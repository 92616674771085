/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VitalScoreApiConfiguration, VitalScoreApiConfigurationParams } from './vital-score-api-configuration';

import { VitalScoreDashboardService } from './services/vital-score-dashboard.service';
import { VitalScoreMonitorService } from './services/vital-score-monitor.service';
import { VitalScoreProfileService } from './services/vital-score-profile.service';
import { VitalScoreReportService } from './services/vital-score-report.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    VitalScoreDashboardService,
    VitalScoreMonitorService,
    VitalScoreProfileService,
    VitalScoreReportService,
    VitalScoreApiConfiguration
  ],
})
export class VitalScoreModule {
  static forRoot(params: VitalScoreApiConfigurationParams): ModuleWithProviders<VitalScoreModule> {
    return {
      ngModule: VitalScoreModule,
      providers: [
        {
          provide: VitalScoreApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: VitalScoreModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('VitalScoreModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
