/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthenticationStrictHttpResponse } from '../../authentication-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AccessTokenModel } from '../../models/access-token-model';
import { LoginRequestModel } from '../../models/login-request-model';

export interface LocalAccountLoginUserPost$Params {
      body?: LoginRequestModel
}

export function localAccountLoginUserPost(http: HttpClient, rootUrl: string, params?: LocalAccountLoginUserPost$Params, context?: HttpContext): Observable<AuthenticationStrictHttpResponse<AccessTokenModel>> {
  const rb = new RequestBuilder(rootUrl, localAccountLoginUserPost.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as AuthenticationStrictHttpResponse<AccessTokenModel>;
    })
  );
}

localAccountLoginUserPost.PATH = '/LocalAccount/LoginUser';
