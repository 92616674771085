/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FaceScanBaseService } from '../face-scan-base-service';
import { FaceScanApiConfiguration } from '../face-scan-api-configuration';
import { FaceScanStrictHttpResponse } from '../face-scan-strict-http-response';

import { DashboardHealthCheckItemModel } from '../models/dashboard-health-check-item-model';
import { dashboardOrganizationScanSummaryOrganizationIdPost } from '../fn/dashboard/dashboard-organization-scan-summary-organization-id-post';
import { DashboardOrganizationScanSummaryOrganizationIdPost$Params } from '../fn/dashboard/dashboard-organization-scan-summary-organization-id-post';

@Injectable({ providedIn: 'root' })
export class FaceScanDashboardService extends FaceScanBaseService {
  constructor(config: FaceScanApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `dashboardOrganizationScanSummaryOrganizationIdPost()` */
  static readonly DashboardOrganizationScanSummaryOrganizationIdPostPath = '/Dashboard/OrganizationScanSummary/{organizationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardOrganizationScanSummaryOrganizationIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dashboardOrganizationScanSummaryOrganizationIdPost$Response(params: DashboardOrganizationScanSummaryOrganizationIdPost$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<Array<DashboardHealthCheckItemModel>>> {
    return dashboardOrganizationScanSummaryOrganizationIdPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dashboardOrganizationScanSummaryOrganizationIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dashboardOrganizationScanSummaryOrganizationIdPost(params: DashboardOrganizationScanSummaryOrganizationIdPost$Params, context?: HttpContext): Observable<Array<DashboardHealthCheckItemModel>> {
    return this.dashboardOrganizationScanSummaryOrganizationIdPost$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<Array<DashboardHealthCheckItemModel>>): Array<DashboardHealthCheckItemModel> => r.body)
    );
  }

}
