/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MPIApiConfiguration, MPIApiConfigurationParams } from './mpi-api-configuration';

import { MPIAdminService } from './services/mpi-admin.service';
import { MPIConsumerService } from './services/mpi-consumer.service';
import { MPIMonitorService } from './services/mpi-monitor.service';
import { MPIPractitionerService } from './services/mpi-practitioner.service';
import { MPISyncService } from './services/mpi-sync.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    MPIAdminService,
    MPIConsumerService,
    MPIMonitorService,
    MPIPractitionerService,
    MPISyncService,
    MPIApiConfiguration
  ],
})
export class MPIModule {
  static forRoot(params: MPIApiConfigurationParams): ModuleWithProviders<MPIModule> {
    return {
      ngModule: MPIModule,
      providers: [
        {
          provide: MPIApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: MPIModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('MPIModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
