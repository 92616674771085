/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FaceScanStrictHttpResponse } from '../../face-scan-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ScanSessionResultModel } from '../../models/scan-session-result-model';

export interface ScanLastScanProfileIdGet$Params {
  profileId: string;
}

export function scanLastScanProfileIdGet(http: HttpClient, rootUrl: string, params: ScanLastScanProfileIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<ScanSessionResultModel>> {
  const rb = new RequestBuilder(rootUrl, scanLastScanProfileIdGet.PATH, 'get');
  if (params) {
    rb.path('profileId', params.profileId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as FaceScanStrictHttpResponse<ScanSessionResultModel>;
    })
  );
}

scanLastScanProfileIdGet.PATH = '/Scan/LastScan/{profileId}';
