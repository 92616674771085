/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { WearablesBaseService } from '../wearables-base-service';
import { WearablesApiConfiguration } from '../wearables-api-configuration';
import { WearablesStrictHttpResponse } from '../wearables-strict-http-response';

import { callbackWebhookPost } from '../fn/callback/callback-webhook-post';
import { CallbackWebhookPost$Params } from '../fn/callback/callback-webhook-post';

@Injectable({ providedIn: 'root' })
export class WearablesCallbackService extends WearablesBaseService {
  constructor(config: WearablesApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `callbackWebhookPost()` */
  static readonly CallbackWebhookPostPath = '/Callback/webhook';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callbackWebhookPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  callbackWebhookPost$Response(params?: CallbackWebhookPost$Params, context?: HttpContext): Observable<WearablesStrictHttpResponse<void>> {
    return callbackWebhookPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callbackWebhookPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  callbackWebhookPost(params?: CallbackWebhookPost$Params, context?: HttpContext): Observable<void> {
    return this.callbackWebhookPost$Response(params, context).pipe(
      map((r: WearablesStrictHttpResponse<void>): void => r.body)
    );
  }

}
