/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FaceScanBaseService } from '../face-scan-base-service';
import { FaceScanApiConfiguration } from '../face-scan-api-configuration';
import { FaceScanStrictHttpResponse } from '../face-scan-strict-http-response';

import { tokenAccessTokenPost } from '../fn/token/token-access-token-post';
import { TokenAccessTokenPost$Params } from '../fn/token/token-access-token-post';
import { TokenResponseModel } from '../models/token-response-model';

@Injectable({ providedIn: 'root' })
export class FaceScanTokenService extends FaceScanBaseService {
  constructor(config: FaceScanApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `tokenAccessTokenPost()` */
  static readonly TokenAccessTokenPostPath = '/Token/AccessToken';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tokenAccessTokenPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tokenAccessTokenPost$Response(params?: TokenAccessTokenPost$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<TokenResponseModel>> {
    return tokenAccessTokenPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tokenAccessTokenPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tokenAccessTokenPost(params?: TokenAccessTokenPost$Params, context?: HttpContext): Observable<TokenResponseModel> {
    return this.tokenAccessTokenPost$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<TokenResponseModel>): TokenResponseModel => r.body)
    );
  }

}
