/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppEnvironmentApiConfiguration, AppEnvironmentApiConfigurationParams } from './app-environment-api-configuration';

import { AppEnvironmentAppEnvironmentService } from './services/app-environment-app-environment.service';
import { AppEnvironmentMonitorService } from './services/app-environment-monitor.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AppEnvironmentAppEnvironmentService,
    AppEnvironmentMonitorService,
    AppEnvironmentApiConfiguration
  ],
})
export class AppEnvironmentModule {
  static forRoot(params: AppEnvironmentApiConfigurationParams): ModuleWithProviders<AppEnvironmentModule> {
    return {
      ngModule: AppEnvironmentModule,
      providers: [
        {
          provide: AppEnvironmentApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: AppEnvironmentModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('AppEnvironmentModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
