/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { VirtualHealthLibraryStrictHttpResponse } from '../../virtual-health-library-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { MediaItemModel } from '../../models/media-item-model';

export interface MediaSearchGet$Params {
  keyWords?: string;
  categoryId?: string | null;
}

export function mediaSearchGet(http: HttpClient, rootUrl: string, params?: MediaSearchGet$Params, context?: HttpContext): Observable<VirtualHealthLibraryStrictHttpResponse<Array<MediaItemModel>>> {
  const rb = new RequestBuilder(rootUrl, mediaSearchGet.PATH, 'get');
  if (params) {
    rb.query('keyWords', params.keyWords, {});
    rb.query('categoryId', params.categoryId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as VirtualHealthLibraryStrictHttpResponse<Array<MediaItemModel>>;
    })
  );
}

mediaSearchGet.PATH = '/Media/Search';
