/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MPIStrictHttpResponse } from '../../mpi-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PersonSyncRequesMessage } from '../../models/person-sync-reques-message';

export interface SyncSyncPersonsPost$Params {
      body?: PersonSyncRequesMessage
}

export function syncSyncPersonsPost(http: HttpClient, rootUrl: string, params?: SyncSyncPersonsPost$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<number>> {
  const rb = new RequestBuilder(rootUrl, syncSyncPersonsPost.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as MPIStrictHttpResponse<number>;
    })
  );
}

syncSyncPersonsPost.PATH = '/Sync/SyncPersons';
