/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VirtualHealthLibraryApiConfiguration, VirtualHealthLibraryApiConfigurationParams } from './virtual-health-library-api-configuration';

import { VirtualHealthLibraryAiService } from './services/virtual-health-library-ai.service';
import { VirtualHealthLibraryMediaService } from './services/virtual-health-library-media.service';
import { VirtualHealthLibraryMonitorService } from './services/virtual-health-library-monitor.service';
import { VirtualHealthLibraryTokenService } from './services/virtual-health-library-token.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    VirtualHealthLibraryAiService,
    VirtualHealthLibraryMediaService,
    VirtualHealthLibraryMonitorService,
    VirtualHealthLibraryTokenService,
    VirtualHealthLibraryApiConfiguration
  ],
})
export class VirtualHealthLibraryModule {
  static forRoot(params: VirtualHealthLibraryApiConfigurationParams): ModuleWithProviders<VirtualHealthLibraryModule> {
    return {
      ngModule: VirtualHealthLibraryModule,
      providers: [
        {
          provide: VirtualHealthLibraryApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: VirtualHealthLibraryModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('VirtualHealthLibraryModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
