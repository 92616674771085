import { HTTP_INTERCEPTORS, HttpClient, HttpInterceptor, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { FaceScanModule } from './api/proxy/facescan/face-scan.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalHttpInterceptorService } from './services/auth-service/global-http-interceptor-service ';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { WearablesModule } from './api/proxy/wearables/wearables.module';
import { VitalScoreModule } from './api/proxy/vitalscore/vital-score.module';
import { MainMenuModule } from './components/main-menu/main-menu.module';
import { AppEnvironmentModule } from './api/proxy/app-environment/app-environment.module';
import { OrganizationModule } from './api/proxy/organization/organization.module';
import { VirtualHealthLibraryModule } from './api/proxy/virtualHealthLibrary/virtual-health-library.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { MPIModule } from './api/proxy/mpi/mpi.module';
import { TokenService } from './services/token/token.service';
import { AuthService } from './services/auth-service/auth.service';
import { UserInfoService } from './services/auth-service/user-info-service';
import { TeamsService } from './services/teams-service/teams.service';
import { EnvironmentConfigService } from './services/environment-config-service/environment-config.service';
import { EventService } from './services/events/event.service';
import { SecureStorageService } from './services/storage/secure-storage.service';
import { StorageService } from './services/storage/storage.service';
import { PointsModule } from './api/proxy/points/points.module';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initializeApp(
    tokenService: TokenService,
    authService: AuthService,
    userInfoService: UserInfoService,
    teamsService: TeamsService,
    secureStorageService: SecureStorageService,
    storageService: StorageService,
    environmentConfigService: EnvironmentConfigService,
    eventService: EventService
): () => Promise<void> {
    return () => {
        return Promise.all([
            tokenService.init(),
            authService.init(),
            userInfoService.init(),
            teamsService.init(),
            secureStorageService.init(),
            storageService.init(),
            environmentConfigService.init(),
            eventService.init()
        ]).then(() => {           
            eventService.notifyBootstrapCompleted.emit(tokenService);
        });
    };
}

@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [HammerModule,
        BrowserModule,
        IonicModule.forRoot({
            rippleEffect: false,
            mode: 'md',
           
            inputShims: true,
            scrollPadding: true,
            hideCaretOnScroll: true,
            scrollAssist: true, // To help manage scroll behavior with keyboard
        }),
        AppRoutingModule,
        BrowserAnimationsModule,
        MainMenuModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        AppEnvironmentModule.forRoot({ rootUrl: environment.appEnvironmentBaseUrl }),
        FaceScanModule.forRoot({ rootUrl: '' }),
        WearablesModule.forRoot({ rootUrl: '' }),
        VitalScoreModule.forRoot({ rootUrl: '' }),
        VirtualHealthLibraryModule.forRoot({ rootUrl: '' }),
        OrganizationModule.forRoot({ rootUrl: '' }),
        MPIModule.forRoot({ rootUrl: '' }),
        PointsModule.forRoot({ rootUrl: '' }),
        SharedModule], providers: [
            {
                provide: APP_INITIALIZER,
                useFactory: initializeApp,
                deps: [TokenService, AuthService, UserInfoService, TeamsService, SecureStorageService, StorageService, EnvironmentConfigService, EventService],  // The services to initialize before app starts
                multi: true
            },
            { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
            { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true },
            provideHttpClient(withInterceptorsFromDi())
        ]
})
export class AppModule {
    constructor() {

    }
}
