/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppEnvironmentStrictHttpResponse } from '../../app-environment-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CountryItemModel } from '../../models/country-item-model';

export interface AppEnvironmentCountriesForUserDeviceTypeAppIdProductionGet$Params {
  deviceType: string;
  appId: string;
  production: boolean;
  emailAddress?: string;
}

export function appEnvironmentCountriesForUserDeviceTypeAppIdProductionGet(http: HttpClient, rootUrl: string, params: AppEnvironmentCountriesForUserDeviceTypeAppIdProductionGet$Params, context?: HttpContext): Observable<AppEnvironmentStrictHttpResponse<Array<CountryItemModel>>> {
  const rb = new RequestBuilder(rootUrl, appEnvironmentCountriesForUserDeviceTypeAppIdProductionGet.PATH, 'get');
  if (params) {
    rb.path('deviceType', params.deviceType, {});
    rb.path('appId', params.appId, {});
    rb.path('production', params.production, {});
    rb.query('emailAddress', params.emailAddress, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as AppEnvironmentStrictHttpResponse<Array<CountryItemModel>>;
    })
  );
}

appEnvironmentCountriesForUserDeviceTypeAppIdProductionGet.PATH = '/AppEnvironment/CountriesForUser/{deviceType}/{appId}/{production}';
