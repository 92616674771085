import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TokenService } from '../token/token.service';
import { LoadingService } from '../loading-service/loading.service';
import { EnvironmentConfigService } from '../environment-config-service/environment-config.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { SecureStorageService } from '../storage/secure-storage.service';
import { SecureStorageKey } from '../storage/models/secure-storage-key.enum';
import { HealthScoreService } from '../health-score-service/health-score.service';
import { MyProfileService } from '../my-profile-service/my-profile.service';
import { UserInfoService } from '../auth-service/user-info-service';
import { TeamsService } from '../teams-service/teams.service';
import { AuthService } from '../auth-service/auth.service';
import { TranslatorService } from '../translator-service/translator.service';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class ServicesStartupService {


  constructor(
    public alertController: AlertController,
    private tokenService: TokenService,
    private _EnvironmentConfigService: EnvironmentConfigService,
    private _StorageService: SecureStorageService,
    private _authService: AuthService
  ) {


    var redirectRoute = this.getParameterByName('route');
    if (redirectRoute) {
      var r = {
        route: redirectRoute
      };
      var jsonRoute = JSON.stringify(r);
      setTimeout(async () => {
        await this._StorageService.set(SecureStorageKey.RedirectRoute, jsonRoute)
      }, 0);
    }

  }


  public async initServices(): Promise<void> {
    if (Capacitor.getPlatform() === "android")
      await SplashScreen.hide();

    await SplashScreen.show({ autoHide: true });
    try {
      await this._authService.init();
      await this._EnvironmentConfigService.EnsureNetworkConfiguration("servicestartup-initServices");     
    } finally {
      await SplashScreen.hide();
    }
  }

  public async resumeApp(): Promise<boolean> {
    try {
      await this._authService.init();
      await this._EnvironmentConfigService.EnsureNetworkConfiguration("servicestartup-resumeApp");      
    } finally {
    }
    return true;
  }

  getParameterByName(name: string, url = window.location.href): string | null {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}
