/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FaceScanStrictHttpResponse } from '../../face-scan-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DataAvailableResponseModel } from '../../models/data-available-response-model';
import { DataRequestModel } from '../../models/data-request-model';

export interface IntegrationDataAvailablePersonIdPost$Params {
  personId: string;
      body?: DataRequestModel
}

export function integrationDataAvailablePersonIdPost(http: HttpClient, rootUrl: string, params: IntegrationDataAvailablePersonIdPost$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<DataAvailableResponseModel>> {
  const rb = new RequestBuilder(rootUrl, integrationDataAvailablePersonIdPost.PATH, 'post');
  if (params) {
    rb.path('personId', params.personId, {});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as FaceScanStrictHttpResponse<DataAvailableResponseModel>;
    })
  );
}

integrationDataAvailablePersonIdPost.PATH = '/Integration/DataAvailable/{personId}';
