/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { WearablesBaseService } from '../wearables-base-service';
import { WearablesApiConfiguration } from '../wearables-api-configuration';
import { WearablesStrictHttpResponse } from '../wearables-strict-http-response';

import { DashboardDeviceItemModel } from '../models/dashboard-device-item-model';
import { dashboardDeviceSummaryOrganizationIdPost } from '../fn/dashboard/dashboard-device-summary-organization-id-post';
import { DashboardDeviceSummaryOrganizationIdPost$Params } from '../fn/dashboard/dashboard-device-summary-organization-id-post';
import { DashboardPhysicalItemModel } from '../models/dashboard-physical-item-model';
import { dashboardPhysicalSummaryOrganizationIdPost } from '../fn/dashboard/dashboard-physical-summary-organization-id-post';
import { DashboardPhysicalSummaryOrganizationIdPost$Params } from '../fn/dashboard/dashboard-physical-summary-organization-id-post';
import { DashboardSleepItemModel } from '../models/dashboard-sleep-item-model';
import { dashboardSleepSummaryOrganizationIdPost } from '../fn/dashboard/dashboard-sleep-summary-organization-id-post';
import { DashboardSleepSummaryOrganizationIdPost$Params } from '../fn/dashboard/dashboard-sleep-summary-organization-id-post';

@Injectable({ providedIn: 'root' })
export class WearablesDashboardService extends WearablesBaseService {
  constructor(config: WearablesApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `dashboardDeviceSummaryOrganizationIdPost()` */
  static readonly DashboardDeviceSummaryOrganizationIdPostPath = '/Dashboard/DeviceSummary/{organizationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardDeviceSummaryOrganizationIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dashboardDeviceSummaryOrganizationIdPost$Response(params: DashboardDeviceSummaryOrganizationIdPost$Params, context?: HttpContext): Observable<WearablesStrictHttpResponse<Array<DashboardDeviceItemModel>>> {
    return dashboardDeviceSummaryOrganizationIdPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dashboardDeviceSummaryOrganizationIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dashboardDeviceSummaryOrganizationIdPost(params: DashboardDeviceSummaryOrganizationIdPost$Params, context?: HttpContext): Observable<Array<DashboardDeviceItemModel>> {
    return this.dashboardDeviceSummaryOrganizationIdPost$Response(params, context).pipe(
      map((r: WearablesStrictHttpResponse<Array<DashboardDeviceItemModel>>): Array<DashboardDeviceItemModel> => r.body)
    );
  }

  /** Path part for operation `dashboardPhysicalSummaryOrganizationIdPost()` */
  static readonly DashboardPhysicalSummaryOrganizationIdPostPath = '/Dashboard/PhysicalSummary/{organizationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardPhysicalSummaryOrganizationIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dashboardPhysicalSummaryOrganizationIdPost$Response(params: DashboardPhysicalSummaryOrganizationIdPost$Params, context?: HttpContext): Observable<WearablesStrictHttpResponse<Array<DashboardPhysicalItemModel>>> {
    return dashboardPhysicalSummaryOrganizationIdPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dashboardPhysicalSummaryOrganizationIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dashboardPhysicalSummaryOrganizationIdPost(params: DashboardPhysicalSummaryOrganizationIdPost$Params, context?: HttpContext): Observable<Array<DashboardPhysicalItemModel>> {
    return this.dashboardPhysicalSummaryOrganizationIdPost$Response(params, context).pipe(
      map((r: WearablesStrictHttpResponse<Array<DashboardPhysicalItemModel>>): Array<DashboardPhysicalItemModel> => r.body)
    );
  }

  /** Path part for operation `dashboardSleepSummaryOrganizationIdPost()` */
  static readonly DashboardSleepSummaryOrganizationIdPostPath = '/Dashboard/SleepSummary/{organizationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardSleepSummaryOrganizationIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dashboardSleepSummaryOrganizationIdPost$Response(params: DashboardSleepSummaryOrganizationIdPost$Params, context?: HttpContext): Observable<WearablesStrictHttpResponse<Array<DashboardSleepItemModel>>> {
    return dashboardSleepSummaryOrganizationIdPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dashboardSleepSummaryOrganizationIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dashboardSleepSummaryOrganizationIdPost(params: DashboardSleepSummaryOrganizationIdPost$Params, context?: HttpContext): Observable<Array<DashboardSleepItemModel>> {
    return this.dashboardSleepSummaryOrganizationIdPost$Response(params, context).pipe(
      map((r: WearablesStrictHttpResponse<Array<DashboardSleepItemModel>>): Array<DashboardSleepItemModel> => r.body)
    );
  }

}
