/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WearablesApiConfiguration, WearablesApiConfigurationParams } from './wearables-api-configuration';

import { WearablesCallbackService } from './services/wearables-callback.service';
import { WearablesDashboardService } from './services/wearables-dashboard.service';
import { WearablesIntegrationService } from './services/wearables-integration.service';
import { WearablesMonitorService } from './services/wearables-monitor.service';
import { WearablesProfileService } from './services/wearables-profile.service';
import { WearablesReportService } from './services/wearables-report.service';
import { WearablesTokenService } from './services/wearables-token.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    WearablesCallbackService,
    WearablesDashboardService,
    WearablesIntegrationService,
    WearablesMonitorService,
    WearablesProfileService,
    WearablesReportService,
    WearablesTokenService,
    WearablesApiConfiguration
  ],
})
export class WearablesModule {
  static forRoot(params: WearablesApiConfigurationParams): ModuleWithProviders<WearablesModule> {
    return {
      ngModule: WearablesModule,
      providers: [
        {
          provide: WearablesApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: WearablesModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('WearablesModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
