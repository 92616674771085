/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { VitalScoreBaseService } from '../vital-score-base-service';
import { VitalScoreApiConfiguration } from '../vital-score-api-configuration';
import { VitalScoreStrictHttpResponse } from '../vital-score-strict-http-response';

import { monitorProbeGet } from '../fn/monitor/monitor-probe-get';
import { MonitorProbeGet$Params } from '../fn/monitor/monitor-probe-get';
import { MonitorProbeResultModel } from '../models/monitor-probe-result-model';
import { monitorServiceBusGet } from '../fn/monitor/monitor-service-bus-get';
import { MonitorServiceBusGet$Params } from '../fn/monitor/monitor-service-bus-get';
import { ServiceBusStatistic } from '../models/service-bus-statistic';

@Injectable({ providedIn: 'root' })
export class VitalScoreMonitorService extends VitalScoreBaseService {
  constructor(config: VitalScoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `monitorServiceBusGet()` */
  static readonly MonitorServiceBusGetPath = '/Monitor/ServiceBus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `monitorServiceBusGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  monitorServiceBusGet$Response(params?: MonitorServiceBusGet$Params, context?: HttpContext): Observable<VitalScoreStrictHttpResponse<ServiceBusStatistic>> {
    return monitorServiceBusGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `monitorServiceBusGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  monitorServiceBusGet(params?: MonitorServiceBusGet$Params, context?: HttpContext): Observable<ServiceBusStatistic> {
    return this.monitorServiceBusGet$Response(params, context).pipe(
      map((r: VitalScoreStrictHttpResponse<ServiceBusStatistic>): ServiceBusStatistic => r.body)
    );
  }

  /** Path part for operation `monitorProbeGet()` */
  static readonly MonitorProbeGetPath = '/Monitor/Probe';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `monitorProbeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  monitorProbeGet$Response(params?: MonitorProbeGet$Params, context?: HttpContext): Observable<VitalScoreStrictHttpResponse<MonitorProbeResultModel>> {
    return monitorProbeGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `monitorProbeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  monitorProbeGet(params?: MonitorProbeGet$Params, context?: HttpContext): Observable<MonitorProbeResultModel> {
    return this.monitorProbeGet$Response(params, context).pipe(
      map((r: VitalScoreStrictHttpResponse<MonitorProbeResultModel>): MonitorProbeResultModel => r.body)
    );
  }

}
