/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MPIStrictHttpResponse } from '../../mpi-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PractitionerSearchModel } from '../../models/practitioner-search-model';
import { PractitionerSearchResultModel } from '../../models/practitioner-search-result-model';

export interface PractitionerSearchPost$Params {
      body?: PractitionerSearchModel
}

export function practitionerSearchPost(http: HttpClient, rootUrl: string, params?: PractitionerSearchPost$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<Array<PractitionerSearchResultModel>>> {
  const rb = new RequestBuilder(rootUrl, practitionerSearchPost.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as MPIStrictHttpResponse<Array<PractitionerSearchResultModel>>;
    })
  );
}

practitionerSearchPost.PATH = '/Practitioner/Search';
