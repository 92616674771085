/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { WearablesStrictHttpResponse } from '../../wearables-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { MemberGoalModel } from '../../models/member-goal-model';

export interface ProfileMemberGoalUpdatePost$Params {
      body?: Array<MemberGoalModel>
}

export function profileMemberGoalUpdatePost(http: HttpClient, rootUrl: string, params?: ProfileMemberGoalUpdatePost$Params, context?: HttpContext): Observable<WearablesStrictHttpResponse<boolean>> {
  const rb = new RequestBuilder(rootUrl, profileMemberGoalUpdatePost.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as WearablesStrictHttpResponse<boolean>;
    })
  );
}

profileMemberGoalUpdatePost.PATH = '/Profile/MemberGoalUpdate';
