/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FaceScanBaseService } from '../face-scan-base-service';
import { FaceScanApiConfiguration } from '../face-scan-api-configuration';
import { FaceScanStrictHttpResponse } from '../face-scan-strict-http-response';

import { adminSearchSystemIdentifierAccessCodePost } from '../fn/admin/admin-search-system-identifier-access-code-post';
import { AdminSearchSystemIdentifierAccessCodePost$Params } from '../fn/admin/admin-search-system-identifier-access-code-post';
import { adminSearchSystemIdentifierPost } from '../fn/admin/admin-search-system-identifier-post';
import { AdminSearchSystemIdentifierPost$Params } from '../fn/admin/admin-search-system-identifier-post';
import { adminSystemIdentifierAccessCodeGetIdGet } from '../fn/admin/admin-system-identifier-access-code-get-id-get';
import { AdminSystemIdentifierAccessCodeGetIdGet$Params } from '../fn/admin/admin-system-identifier-access-code-get-id-get';
import { adminSystemIdentifierAccessSaveIdPost } from '../fn/admin/admin-system-identifier-access-save-id-post';
import { AdminSystemIdentifierAccessSaveIdPost$Params } from '../fn/admin/admin-system-identifier-access-save-id-post';
import { adminSystemIdentifierGet } from '../fn/admin/admin-system-identifier-get';
import { AdminSystemIdentifierGet$Params } from '../fn/admin/admin-system-identifier-get';
import { AdminSystemIdentifierModelSearchResultModel } from '../models/admin-system-identifier-model-search-result-model';
import { adminSystemKeySetupGetSystemIdentifierIdIdGet } from '../fn/admin/admin-system-key-setup-get-system-identifier-id-id-get';
import { AdminSystemKeySetupGetSystemIdentifierIdIdGet$Params } from '../fn/admin/admin-system-key-setup-get-system-identifier-id-id-get';
import { adminSystemKeySetupListGetSystemIdentifierIdGet } from '../fn/admin/admin-system-key-setup-list-get-system-identifier-id-get';
import { AdminSystemKeySetupListGetSystemIdentifierIdGet$Params } from '../fn/admin/admin-system-key-setup-list-get-system-identifier-id-get';
import { adminSystemKeySetupSaveSystemIdentifierIdIdPost } from '../fn/admin/admin-system-key-setup-save-system-identifier-id-id-post';
import { AdminSystemKeySetupSaveSystemIdentifierIdIdPost$Params } from '../fn/admin/admin-system-key-setup-save-system-identifier-id-id-post';
import { KeyValueModel } from '../models/key-value-model';
import { SystemIdentifierAccessCodeModel } from '../models/system-identifier-access-code-model';
import { SystemIdentifierAccessCodeModelSearchResultModel } from '../models/system-identifier-access-code-model-search-result-model';
import { SystemKeySetupModel } from '../models/system-key-setup-model';

@Injectable({ providedIn: 'root' })
export class FaceScanAdminService extends FaceScanBaseService {
  constructor(config: FaceScanApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminSearchSystemIdentifierAccessCodePost()` */
  static readonly AdminSearchSystemIdentifierAccessCodePostPath = '/Admin/SearchSystemIdentifierAccessCode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSearchSystemIdentifierAccessCodePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSearchSystemIdentifierAccessCodePost$Response(params?: AdminSearchSystemIdentifierAccessCodePost$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<SystemIdentifierAccessCodeModelSearchResultModel>> {
    return adminSearchSystemIdentifierAccessCodePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSearchSystemIdentifierAccessCodePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSearchSystemIdentifierAccessCodePost(params?: AdminSearchSystemIdentifierAccessCodePost$Params, context?: HttpContext): Observable<SystemIdentifierAccessCodeModelSearchResultModel> {
    return this.adminSearchSystemIdentifierAccessCodePost$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<SystemIdentifierAccessCodeModelSearchResultModel>): SystemIdentifierAccessCodeModelSearchResultModel => r.body)
    );
  }

  /** Path part for operation `adminSystemIdentifierAccessCodeGetIdGet()` */
  static readonly AdminSystemIdentifierAccessCodeGetIdGetPath = '/Admin/SystemIdentifierAccessCodeGet/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSystemIdentifierAccessCodeGetIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSystemIdentifierAccessCodeGetIdGet$Response(params: AdminSystemIdentifierAccessCodeGetIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<SystemIdentifierAccessCodeModel>> {
    return adminSystemIdentifierAccessCodeGetIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSystemIdentifierAccessCodeGetIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSystemIdentifierAccessCodeGetIdGet(params: AdminSystemIdentifierAccessCodeGetIdGet$Params, context?: HttpContext): Observable<SystemIdentifierAccessCodeModel> {
    return this.adminSystemIdentifierAccessCodeGetIdGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<SystemIdentifierAccessCodeModel>): SystemIdentifierAccessCodeModel => r.body)
    );
  }

  /** Path part for operation `adminSystemIdentifierAccessSaveIdPost()` */
  static readonly AdminSystemIdentifierAccessSaveIdPostPath = '/Admin/SystemIdentifierAccessSave/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSystemIdentifierAccessSaveIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSystemIdentifierAccessSaveIdPost$Response(params: AdminSystemIdentifierAccessSaveIdPost$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<string>> {
    return adminSystemIdentifierAccessSaveIdPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSystemIdentifierAccessSaveIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSystemIdentifierAccessSaveIdPost(params: AdminSystemIdentifierAccessSaveIdPost$Params, context?: HttpContext): Observable<string> {
    return this.adminSystemIdentifierAccessSaveIdPost$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `adminSystemIdentifierGet()` */
  static readonly AdminSystemIdentifierGetPath = '/Admin/SystemIdentifier';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSystemIdentifierGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSystemIdentifierGet$Response(params?: AdminSystemIdentifierGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<Array<KeyValueModel>>> {
    return adminSystemIdentifierGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSystemIdentifierGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSystemIdentifierGet(params?: AdminSystemIdentifierGet$Params, context?: HttpContext): Observable<Array<KeyValueModel>> {
    return this.adminSystemIdentifierGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<Array<KeyValueModel>>): Array<KeyValueModel> => r.body)
    );
  }

  /** Path part for operation `adminSearchSystemIdentifierPost()` */
  static readonly AdminSearchSystemIdentifierPostPath = '/Admin/SearchSystemIdentifier';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSearchSystemIdentifierPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSearchSystemIdentifierPost$Response(params?: AdminSearchSystemIdentifierPost$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<AdminSystemIdentifierModelSearchResultModel>> {
    return adminSearchSystemIdentifierPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSearchSystemIdentifierPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSearchSystemIdentifierPost(params?: AdminSearchSystemIdentifierPost$Params, context?: HttpContext): Observable<AdminSystemIdentifierModelSearchResultModel> {
    return this.adminSearchSystemIdentifierPost$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<AdminSystemIdentifierModelSearchResultModel>): AdminSystemIdentifierModelSearchResultModel => r.body)
    );
  }

  /** Path part for operation `adminSystemKeySetupListGetSystemIdentifierIdGet()` */
  static readonly AdminSystemKeySetupListGetSystemIdentifierIdGetPath = '/Admin/SystemKeySetupListGet/{systemIdentifierId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSystemKeySetupListGetSystemIdentifierIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSystemKeySetupListGetSystemIdentifierIdGet$Response(params: AdminSystemKeySetupListGetSystemIdentifierIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<Array<SystemKeySetupModel>>> {
    return adminSystemKeySetupListGetSystemIdentifierIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSystemKeySetupListGetSystemIdentifierIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSystemKeySetupListGetSystemIdentifierIdGet(params: AdminSystemKeySetupListGetSystemIdentifierIdGet$Params, context?: HttpContext): Observable<Array<SystemKeySetupModel>> {
    return this.adminSystemKeySetupListGetSystemIdentifierIdGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<Array<SystemKeySetupModel>>): Array<SystemKeySetupModel> => r.body)
    );
  }

  /** Path part for operation `adminSystemKeySetupGetSystemIdentifierIdIdGet()` */
  static readonly AdminSystemKeySetupGetSystemIdentifierIdIdGetPath = '/Admin/SystemKeySetupGet/{systemIdentifierId}/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSystemKeySetupGetSystemIdentifierIdIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSystemKeySetupGetSystemIdentifierIdIdGet$Response(params: AdminSystemKeySetupGetSystemIdentifierIdIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<SystemKeySetupModel>> {
    return adminSystemKeySetupGetSystemIdentifierIdIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSystemKeySetupGetSystemIdentifierIdIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSystemKeySetupGetSystemIdentifierIdIdGet(params: AdminSystemKeySetupGetSystemIdentifierIdIdGet$Params, context?: HttpContext): Observable<SystemKeySetupModel> {
    return this.adminSystemKeySetupGetSystemIdentifierIdIdGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<SystemKeySetupModel>): SystemKeySetupModel => r.body)
    );
  }

  /** Path part for operation `adminSystemKeySetupSaveSystemIdentifierIdIdPost()` */
  static readonly AdminSystemKeySetupSaveSystemIdentifierIdIdPostPath = '/Admin/SystemKeySetupSave/{systemIdentifierId}/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSystemKeySetupSaveSystemIdentifierIdIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSystemKeySetupSaveSystemIdentifierIdIdPost$Response(params: AdminSystemKeySetupSaveSystemIdentifierIdIdPost$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<string>> {
    return adminSystemKeySetupSaveSystemIdentifierIdIdPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSystemKeySetupSaveSystemIdentifierIdIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSystemKeySetupSaveSystemIdentifierIdIdPost(params: AdminSystemKeySetupSaveSystemIdentifierIdIdPost$Params, context?: HttpContext): Observable<string> {
    return this.adminSystemKeySetupSaveSystemIdentifierIdIdPost$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<string>): string => r.body)
    );
  }

}
