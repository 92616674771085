/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { OrganizationStrictHttpResponse } from '../../organization-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OrganizationReportItem } from '../../models/organization-report-item';

export interface ReportOrganizationDetailGet$Params {
  organizationId?: string | null;
}

export function reportOrganizationDetailGet(http: HttpClient, rootUrl: string, params?: ReportOrganizationDetailGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<Array<OrganizationReportItem>>> {
  const rb = new RequestBuilder(rootUrl, reportOrganizationDetailGet.PATH, 'get');
  if (params) {
    rb.query('organizationId', params.organizationId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as OrganizationStrictHttpResponse<Array<OrganizationReportItem>>;
    })
  );
}

reportOrganizationDetailGet.PATH = '/Report/OrganizationDetail';
