import config from "capacitor.config";

export const sharedConfig = {
	vivaLiteScanSystemId: 'E61CF419-FD6A-44B9-BF35-C27D4F61B644',
	vivaLiteApplicationId: 'com.alulatechnologies.vitalscore',
	vivaLiteHealthCheckBase: 'https://api.alulahealth.com/health/za/digitalhealthcheck',

	version: '1.272',
	build: 372,
	minimumAge: 18,
	webAppId: config.appId,
	availablelanguages: [
		{ code: 'en', text: 'English', dir: 'ltr' },
	],
	imperialCountries: ['us', 'gb', 'lr', 'mm']
};


