/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OrganizationBaseService } from '../organization-base-service';
import { OrganizationApiConfiguration } from '../organization-api-configuration';
import { OrganizationStrictHttpResponse } from '../organization-strict-http-response';

import { OrganizationSyncModel } from '../models/organization-sync-model';
import { syncSyncOrganizationsPost } from '../fn/sync/sync-sync-organizations-post';
import { SyncSyncOrganizationsPost$Params } from '../fn/sync/sync-sync-organizations-post';

@Injectable({ providedIn: 'root' })
export class OrganizationSyncService extends OrganizationBaseService {
  constructor(config: OrganizationApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `syncSyncOrganizationsPost()` */
  static readonly SyncSyncOrganizationsPostPath = '/Sync/SyncOrganizations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `syncSyncOrganizationsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  syncSyncOrganizationsPost$Response(params?: SyncSyncOrganizationsPost$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<OrganizationSyncModel>> {
    return syncSyncOrganizationsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `syncSyncOrganizationsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  syncSyncOrganizationsPost(params?: SyncSyncOrganizationsPost$Params, context?: HttpContext): Observable<OrganizationSyncModel> {
    return this.syncSyncOrganizationsPost$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<OrganizationSyncModel>): OrganizationSyncModel => r.body)
    );
  }

}
