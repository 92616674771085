/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PointsBaseService } from '../points-base-service';
import { PointsApiConfiguration } from '../points-api-configuration';
import { PointsStrictHttpResponse } from '../points-strict-http-response';

import { consumerCurrentBalanceGet } from '../fn/consumer/consumer-current-balance-get';
import { ConsumerCurrentBalanceGet$Params } from '../fn/consumer/consumer-current-balance-get';
import { consumerHistoryPost } from '../fn/consumer/consumer-history-post';
import { ConsumerHistoryPost$Params } from '../fn/consumer/consumer-history-post';
import { CurrentBalanceModel } from '../models/current-balance-model';
import { PointsHistoryResponse } from '../models/points-history-response';

@Injectable({ providedIn: 'root' })
export class PointsConsumerService extends PointsBaseService {
  constructor(config: PointsApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `consumerCurrentBalanceGet()` */
  static readonly ConsumerCurrentBalanceGetPath = '/Consumer/CurrentBalance';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerCurrentBalanceGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerCurrentBalanceGet$Response(params?: ConsumerCurrentBalanceGet$Params, context?: HttpContext): Observable<PointsStrictHttpResponse<CurrentBalanceModel>> {
    return consumerCurrentBalanceGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerCurrentBalanceGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerCurrentBalanceGet(params?: ConsumerCurrentBalanceGet$Params, context?: HttpContext): Observable<CurrentBalanceModel> {
    return this.consumerCurrentBalanceGet$Response(params, context).pipe(
      map((r: PointsStrictHttpResponse<CurrentBalanceModel>): CurrentBalanceModel => r.body)
    );
  }

  /** Path part for operation `consumerHistoryPost()` */
  static readonly ConsumerHistoryPostPath = '/Consumer/History';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerHistoryPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerHistoryPost$Response(params?: ConsumerHistoryPost$Params, context?: HttpContext): Observable<PointsStrictHttpResponse<PointsHistoryResponse>> {
    return consumerHistoryPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerHistoryPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerHistoryPost(params?: ConsumerHistoryPost$Params, context?: HttpContext): Observable<PointsHistoryResponse> {
    return this.consumerHistoryPost$Response(params, context).pipe(
      map((r: PointsStrictHttpResponse<PointsHistoryResponse>): PointsHistoryResponse => r.body)
    );
  }

}
