/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MPIBaseService } from '../mpi-base-service';
import { MPIApiConfiguration } from '../mpi-api-configuration';
import { MPIStrictHttpResponse } from '../mpi-strict-http-response';

import { PractitionerAdminModel } from '../models/practitioner-admin-model';
import { practitionerPersonIdDelete } from '../fn/practitioner/practitioner-person-id-delete';
import { PractitionerPersonIdDelete$Params } from '../fn/practitioner/practitioner-person-id-delete';
import { practitionerPersonIdDeleteRoleRoleIdDelete } from '../fn/practitioner/practitioner-person-id-delete-role-role-id-delete';
import { PractitionerPersonIdDeleteRoleRoleIdDelete$Params } from '../fn/practitioner/practitioner-person-id-delete-role-role-id-delete';
import { practitionerPersonIdGet } from '../fn/practitioner/practitioner-person-id-get';
import { PractitionerPersonIdGet$Params } from '../fn/practitioner/practitioner-person-id-get';
import { practitionerPersonIdGetRolesGet } from '../fn/practitioner/practitioner-person-id-get-roles-get';
import { PractitionerPersonIdGetRolesGet$Params } from '../fn/practitioner/practitioner-person-id-get-roles-get';
import { practitionerPersonIdPhotoPost } from '../fn/practitioner/practitioner-person-id-photo-post';
import { PractitionerPersonIdPhotoPost$Params } from '../fn/practitioner/practitioner-person-id-photo-post';
import { practitionerPersonIdSaveRolesPost } from '../fn/practitioner/practitioner-person-id-save-roles-post';
import { PractitionerPersonIdSaveRolesPost$Params } from '../fn/practitioner/practitioner-person-id-save-roles-post';
import { practitionerPost } from '../fn/practitioner/practitioner-post';
import { PractitionerPost$Params } from '../fn/practitioner/practitioner-post';
import { PractitionerRoleModel } from '../models/practitioner-role-model';
import { practitionerSearchPost } from '../fn/practitioner/practitioner-search-post';
import { PractitionerSearchPost$Params } from '../fn/practitioner/practitioner-search-post';
import { PractitionerSearchResultModel } from '../models/practitioner-search-result-model';

@Injectable({ providedIn: 'root' })
export class MPIPractitionerService extends MPIBaseService {
  constructor(config: MPIApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `practitionerPost()` */
  static readonly PractitionerPostPath = '/Practitioner';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `practitionerPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practitionerPost$Response(params?: PractitionerPost$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<string>> {
    return practitionerPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `practitionerPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practitionerPost(params?: PractitionerPost$Params, context?: HttpContext): Observable<string> {
    return this.practitionerPost$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `practitionerPersonIdGet()` */
  static readonly PractitionerPersonIdGetPath = '/Practitioner/{personId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `practitionerPersonIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  practitionerPersonIdGet$Response(params: PractitionerPersonIdGet$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<PractitionerAdminModel>> {
    return practitionerPersonIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `practitionerPersonIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  practitionerPersonIdGet(params: PractitionerPersonIdGet$Params, context?: HttpContext): Observable<PractitionerAdminModel> {
    return this.practitionerPersonIdGet$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<PractitionerAdminModel>): PractitionerAdminModel => r.body)
    );
  }

  /** Path part for operation `practitionerPersonIdDelete()` */
  static readonly PractitionerPersonIdDeletePath = '/Practitioner/{personId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `practitionerPersonIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  practitionerPersonIdDelete$Response(params: PractitionerPersonIdDelete$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<void>> {
    return practitionerPersonIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `practitionerPersonIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  practitionerPersonIdDelete(params: PractitionerPersonIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.practitionerPersonIdDelete$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `practitionerSearchPost()` */
  static readonly PractitionerSearchPostPath = '/Practitioner/Search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `practitionerSearchPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practitionerSearchPost$Response(params?: PractitionerSearchPost$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<Array<PractitionerSearchResultModel>>> {
    return practitionerSearchPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `practitionerSearchPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practitionerSearchPost(params?: PractitionerSearchPost$Params, context?: HttpContext): Observable<Array<PractitionerSearchResultModel>> {
    return this.practitionerSearchPost$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<Array<PractitionerSearchResultModel>>): Array<PractitionerSearchResultModel> => r.body)
    );
  }

  /** Path part for operation `practitionerPersonIdSaveRolesPost()` */
  static readonly PractitionerPersonIdSaveRolesPostPath = '/Practitioner/{personId}/SaveRoles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `practitionerPersonIdSaveRolesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practitionerPersonIdSaveRolesPost$Response(params: PractitionerPersonIdSaveRolesPost$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<string>> {
    return practitionerPersonIdSaveRolesPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `practitionerPersonIdSaveRolesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practitionerPersonIdSaveRolesPost(params: PractitionerPersonIdSaveRolesPost$Params, context?: HttpContext): Observable<string> {
    return this.practitionerPersonIdSaveRolesPost$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `practitionerPersonIdGetRolesGet()` */
  static readonly PractitionerPersonIdGetRolesGetPath = '/Practitioner/{personId}/GetRoles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `practitionerPersonIdGetRolesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  practitionerPersonIdGetRolesGet$Response(params: PractitionerPersonIdGetRolesGet$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<Array<PractitionerRoleModel>>> {
    return practitionerPersonIdGetRolesGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `practitionerPersonIdGetRolesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  practitionerPersonIdGetRolesGet(params: PractitionerPersonIdGetRolesGet$Params, context?: HttpContext): Observable<Array<PractitionerRoleModel>> {
    return this.practitionerPersonIdGetRolesGet$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<Array<PractitionerRoleModel>>): Array<PractitionerRoleModel> => r.body)
    );
  }

  /** Path part for operation `practitionerPersonIdDeleteRoleRoleIdDelete()` */
  static readonly PractitionerPersonIdDeleteRoleRoleIdDeletePath = '/Practitioner/{personId}/DeleteRole/{roleId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `practitionerPersonIdDeleteRoleRoleIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  practitionerPersonIdDeleteRoleRoleIdDelete$Response(params: PractitionerPersonIdDeleteRoleRoleIdDelete$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<void>> {
    return practitionerPersonIdDeleteRoleRoleIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `practitionerPersonIdDeleteRoleRoleIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  practitionerPersonIdDeleteRoleRoleIdDelete(params: PractitionerPersonIdDeleteRoleRoleIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.practitionerPersonIdDeleteRoleRoleIdDelete$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `practitionerPersonIdPhotoPost()` */
  static readonly PractitionerPersonIdPhotoPostPath = '/Practitioner/{personId}/Photo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `practitionerPersonIdPhotoPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practitionerPersonIdPhotoPost$Response(params: PractitionerPersonIdPhotoPost$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<string>> {
    return practitionerPersonIdPhotoPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `practitionerPersonIdPhotoPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  practitionerPersonIdPhotoPost(params: PractitionerPersonIdPhotoPost$Params, context?: HttpContext): Observable<string> {
    return this.practitionerPersonIdPhotoPost$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<string>): string => r.body)
    );
  }

}
