/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PointsApiConfiguration, PointsApiConfigurationParams } from './points-api-configuration';

import { PointsConsumerService } from './services/points-consumer.service';
import { PointsMonitorService } from './services/points-monitor.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    PointsConsumerService,
    PointsMonitorService,
    PointsApiConfiguration
  ],
})
export class PointsModule {
  static forRoot(params: PointsApiConfigurationParams): ModuleWithProviders<PointsModule> {
    return {
      ngModule: PointsModule,
      providers: [
        {
          provide: PointsApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: PointsModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('PointsModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
