import { registerPlugin, WebPlugin } from '@capacitor/core';

export interface UATDetectorPlugin {
    detect(): Promise<{ installSource: string, allowUAT: boolean }>;
}

export class UATDetectorPluginWeb extends WebPlugin implements UATDetectorPlugin {
    async detect(): Promise<{ installSource: string, allowUAT: boolean }> {
        // Check if the URL contains 'uat' to determine if UAT is allowed
        const installSource = window.location.href;

        const allowUAT = installSource.includes("-uat") || installSource.includes('localhost');
  
        // Set installSource as 'Web'
  
        return {
            installSource,
            allowUAT
        };
    }
}

// Register the plugin for all platforms, including web
const UATDetector = registerPlugin<UATDetectorPlugin>('UATDetector', {
    web: () => new UATDetectorPluginWeb(), // Register the web implementation here
});

export default UATDetector;
