/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NotifyBaseService } from '../notify-base-service';
import { NotifyApiConfiguration } from '../notify-api-configuration';
import { NotifyStrictHttpResponse } from '../notify-strict-http-response';

import { consumerNotificationHistoryGet } from '../fn/consumer/consumer-notification-history-get';
import { ConsumerNotificationHistoryGet$Params } from '../fn/consumer/consumer-notification-history-get';
import { consumerUnreadNotificationsGet } from '../fn/consumer/consumer-unread-notifications-get';
import { ConsumerUnreadNotificationsGet$Params } from '../fn/consumer/consumer-unread-notifications-get';
import { NotificationHistoryItem } from '../models/notification-history-item';

@Injectable({ providedIn: 'root' })
export class NotifyConsumerService extends NotifyBaseService {
  constructor(config: NotifyApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `consumerNotificationHistoryGet()` */
  static readonly ConsumerNotificationHistoryGetPath = '/Consumer/NotificationHistory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerNotificationHistoryGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerNotificationHistoryGet$Response(params?: ConsumerNotificationHistoryGet$Params, context?: HttpContext): Observable<NotifyStrictHttpResponse<Array<NotificationHistoryItem>>> {
    return consumerNotificationHistoryGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerNotificationHistoryGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerNotificationHistoryGet(params?: ConsumerNotificationHistoryGet$Params, context?: HttpContext): Observable<Array<NotificationHistoryItem>> {
    return this.consumerNotificationHistoryGet$Response(params, context).pipe(
      map((r: NotifyStrictHttpResponse<Array<NotificationHistoryItem>>): Array<NotificationHistoryItem> => r.body)
    );
  }

  /** Path part for operation `consumerUnreadNotificationsGet()` */
  static readonly ConsumerUnreadNotificationsGetPath = '/Consumer/UnreadNotifications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerUnreadNotificationsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerUnreadNotificationsGet$Response(params?: ConsumerUnreadNotificationsGet$Params, context?: HttpContext): Observable<NotifyStrictHttpResponse<number>> {
    return consumerUnreadNotificationsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerUnreadNotificationsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerUnreadNotificationsGet(params?: ConsumerUnreadNotificationsGet$Params, context?: HttpContext): Observable<number> {
    return this.consumerUnreadNotificationsGet$Response(params, context).pipe(
      map((r: NotifyStrictHttpResponse<number>): number => r.body)
    );
  }

}
