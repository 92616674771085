/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FaceScanStrictHttpResponse } from '../../face-scan-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SessionResultModel } from '../../models/session-result-model';

export interface SessionSessionHistorySystemIdentifierIdProfileIdGet$Params {
  systemIdentifierId: string;
  profileId: string;
  fromDate?: string;
  toDate?: string;
}

export function sessionSessionHistorySystemIdentifierIdProfileIdGet(http: HttpClient, rootUrl: string, params: SessionSessionHistorySystemIdentifierIdProfileIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<Array<SessionResultModel>>> {
  const rb = new RequestBuilder(rootUrl, sessionSessionHistorySystemIdentifierIdProfileIdGet.PATH, 'get');
  if (params) {
    rb.path('systemIdentifierId', params.systemIdentifierId, {});
    rb.path('profileId', params.profileId, {});
    rb.query('fromDate', params.fromDate, {});
    rb.query('toDate', params.toDate, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as FaceScanStrictHttpResponse<Array<SessionResultModel>>;
    })
  );
}

sessionSessionHistorySystemIdentifierIdProfileIdGet.PATH = '/Session/SessionHistory/{systemIdentifierId}/{profileId}';
