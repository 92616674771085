/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { WearablesStrictHttpResponse } from '../../wearables-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ProfileSummaryRequestModel } from '../../models/profile-summary-request-model';
import { SummaryModel } from '../../models/summary-model';

export interface ProfileSummaryPost$Params {
      body?: ProfileSummaryRequestModel
}

export function profileSummaryPost(http: HttpClient, rootUrl: string, params?: ProfileSummaryPost$Params, context?: HttpContext): Observable<WearablesStrictHttpResponse<SummaryModel>> {
  const rb = new RequestBuilder(rootUrl, profileSummaryPost.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as WearablesStrictHttpResponse<SummaryModel>;
    })
  );
}

profileSummaryPost.PATH = '/Profile/Summary';
