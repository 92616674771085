/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FaceScanApiConfiguration, FaceScanApiConfigurationParams } from './face-scan-api-configuration';

import { FaceScanAdminService } from './services/face-scan-admin.service';
import { FaceScanCallbackService } from './services/face-scan-callback.service';
import { FaceScanDashboardService } from './services/face-scan-dashboard.service';
import { FaceScanIntegrationService } from './services/face-scan-integration.service';
import { FaceScanScanService } from './services/face-scan-scan.service';
import { FaceScanSessionService } from './services/face-scan-session.service';
import { FaceScanTokenService } from './services/face-scan-token.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    FaceScanAdminService,
    FaceScanCallbackService,
    FaceScanDashboardService,
    FaceScanIntegrationService,
    FaceScanScanService,
    FaceScanSessionService,
    FaceScanTokenService,
    FaceScanApiConfiguration
  ],
})
export class FaceScanModule {
  static forRoot(params: FaceScanApiConfigurationParams): ModuleWithProviders<FaceScanModule> {
    return {
      ngModule: FaceScanModule,
      providers: [
        {
          provide: FaceScanApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: FaceScanModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('FaceScanModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
