import { Injectable } from '@angular/core';
import { SecureStorageService } from '../storage/secure-storage.service';
import { App, AppInfo } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { AppEnvironmentAppEnvironmentService } from 'src/app/api/proxy/app-environment/app-environment-services';
import { CountryItemModel, GetVivaScoreRequestModel, IAppEnvironment, VivaPingRequest } from 'src/app/api/proxy/app-environment/app-environment-models';
import { firstValueFrom, timeout } from 'rxjs';
import { StorageKey } from '../storage/models/storage-key.enum';
import { SecureStorageKey } from '../storage/models/secure-storage-key.enum';
import { environment } from 'src/environments/environment';
import { Browser } from '@capacitor/browser';
import { VitalScoreApiConfiguration } from 'src/app/api/proxy/vitalscore/vital-score-api-configuration';
import { FaceScanApiConfiguration } from 'src/app/api/proxy/facescan/face-scan-api-configuration';
import { WearablesApiConfiguration } from 'src/app/api/proxy/wearables/wearables-api-configuration';
import { AuthenticationApiConfiguration } from 'src/app/api/proxy/auth/authentication-api-configuration';
import { NotifyConsumerService, NotifyPushNotifyProviderService } from 'src/app/api/proxy/notify/notify-services';
import { VirtualHealthLibraryApiConfiguration } from 'src/app/api/proxy/virtualHealthLibrary/virtual-health-library-api-configuration';
import { OrganizationApiConfiguration } from 'src/app/api/proxy/organization/organization-api-configuration';
import { SystemLogService } from '../systemlog-service/systemlog.service';
import { Device } from '@capacitor/device';
import { UserData } from '../auth-service/user-data.model';
import { EventService } from '../events/event.service';
import { PromptService } from '../promtp-service/prompt.service';
import { MPIConsumerService } from 'src/app/api/proxy/mpi/mpi-services';
import UATDetector from 'src/app/plugins/uat-detector/uat-detector-plugin';
import { PointsConsumerService } from 'src/app/api/proxy/points/points-services';
import { sharedConfig } from 'src/environments/shared-config';


@Injectable({
  providedIn: 'root',
})
export class EnvironmentConfigService {

  private PING_TIMEOUT = 15000;
  private NETWORK_TIMEOUT = 20000;

  private _IAppEnvironment: ILocalAppEnvironment | null = null;
  private _CountryList?: CountryItemModel[];
  private _SelectedRegion: CountryItemModel | null | undefined = null;
  private _Production = environment.production;

  private _allowUAT: boolean = false;
  private _installSource?: string | null;

  constructor(
    private _AppEnvironmentAppEnvironmentService: AppEnvironmentAppEnvironmentService,
    private _StorageService: SecureStorageService,
    private _systemLogService: SystemLogService,
    private _VitalScoreApiConfiguration: VitalScoreApiConfiguration,
    private _FaceScanApiConfiguration: FaceScanApiConfiguration,
    private _WearablesApiConfiguration: WearablesApiConfiguration,
    private _authenticationConfiguraiton: AuthenticationApiConfiguration,
    private _notificationProviderService: NotifyPushNotifyProviderService,
    private _virtualHealthLibraryConfiguration: VirtualHealthLibraryApiConfiguration,
    private _organizationsService: OrganizationApiConfiguration,
    private _notificationService: NotifyConsumerService,
    private _mpiService: MPIConsumerService,
    private _pointService: PointsConsumerService,
    private _EventService: EventService,
    private _PromptService: PromptService

  ) {
  }


  private initialized = false;

  public async init(): Promise<void> {
    this.initialized = true;

    setTimeout( 
      async () =>{
        var env = await UATDetector.detect();
        if (env) {
          this._allowUAT = env.allowUAT;
          this._installSource = env.installSource;
        }
      }, 10
    );
   
  }

  public isInitialized(): boolean {
    return this.initialized;
  }

  public UserCanLogin(): boolean {
    return this._IAppEnvironment != null;
  }

  public async AllowUAT(): Promise<boolean> {
    if (!this.initialized)
      await this.init();

    return this._allowUAT;
  }

  public IsProduction():boolean{
    return this._Production;
  }

  public InstallSource():string | null | undefined {
    return this._installSource!;
  }

  public async SwitchUAT(production: boolean): Promise<boolean> {
    if (!this.initialized)
      await this.init();

    if (!this._allowUAT)
      return false;

    this.userCountries = null;

   
    this._Production = production;
    await this.LoadConfigurationAsync(true,true);
    return true;

  }

  private async LoadConfigurationAsync(forced: boolean, isUATToggle:boolean = false): Promise<void> {
    var settings = await this._StorageService.get(StorageKey.EnvironmentSettings);
    if (settings) {
      this._IAppEnvironment = JSON.parse(settings);

      if(this._allowUAT && this._IAppEnvironment && !isUATToggle){
        this._Production = this._IAppEnvironment?.production;
      }
      this.setupNetworkLocations();
      this._EventService.environmentLoaded.emit(this._IAppEnvironment!);
    }

    // Retrieve the current country from storage
    var currentCountry = await this._StorageService.get(SecureStorageKey.CurrentRegion);
    if (currentCountry) {
      this._SelectedRegion = JSON.parse(currentCountry);
    } else {
      return;
    }

    // Retrieve app info
    var appInfo: AppInfo = {
      name: window.location.hostname,
      id: sharedConfig.webAppId!,
      version: sharedConfig.version,
      build: sharedConfig.build.toString(),
    };

    if (Capacitor.getPlatform() != 'web') {
      appInfo = await App.getInfo();
    }

    

    // Retrieve the last fetch time and the last used country code
    var lastFetchTime = await this._StorageService.get(SecureStorageKey.LastEnvironmentFetchTime);

    const now = new Date().getTime();
    const oneHour = 60 * 60 * 1000 * 12; // 12 hour in milliseconds

    if (forced) {
      lastFetchTime = null;
    }

    if (this._IAppEnvironment) {
      if (!this._IAppEnvironment.version
        || !this._IAppEnvironment.build
        || this._IAppEnvironment.version != appInfo.version
        || this._IAppEnvironment.build != appInfo.build
        || this._IAppEnvironment.production != this._Production) {
        //version updates - we have to refetch to test if the app has been discontinued
        lastFetchTime = null;
      }
    } else {
      lastFetchTime = null;
    }

    // Check if we need to fetch new settings
    if (!lastFetchTime || // No fetch has been made
      now - parseInt(lastFetchTime) > oneHour // More than an hour has passed      
    ) {
      // Make the network call
      let networkCall = this._AppEnvironmentAppEnvironmentService.appEnvironmentEnvironmentSetupEnvironmentNameCountryCodeDeviceTypeNameAppIdVersionBuildProductionGet({
        environmentName: environment.environmentName,
        countryCode: this._SelectedRegion!.countryCode!,
        deviceType: Capacitor.getPlatform(),
        name: appInfo.name,
        appId: appInfo.id,
        version: appInfo.version,
        build: Number(appInfo.build),
        production: this._Production,
      });

      try {
        // Apply a timeout of 10 seconds to the network call
        var result = await firstValueFrom(networkCall.pipe(timeout(this.NETWORK_TIMEOUT)));
        var localSettings = result as ILocalAppEnvironment;
        localSettings.build = appInfo.build;
        localSettings.deviceType = Capacitor.getPlatform();
        localSettings.id = appInfo.id;
        localSettings.name = appInfo.name;
        localSettings.version = appInfo.version;
        localSettings.countryCode = this._SelectedRegion!.countryCode!;
        localSettings.production = this._Production;
        localSettings.installSource = this._installSource;

        await this._StorageService.set(SecureStorageKey.LastEnvironmentFetchTime, now.toString());
        await this.SaveEnvironmentConfig(localSettings);
        this.setupNetworkLocations();
      } catch (error) {
        await this._PromptService.showNetworkConnectionError(error, 'LoadConfigurationAsync');
      }

    }



  }

  private setupNetworkLocations(): void {
    if (this._IAppEnvironment) {
      try {
        this._VitalScoreApiConfiguration.rootUrl = this._IAppEnvironment!.vitalScoreConfig!.vitalScoreBase!;
        this._FaceScanApiConfiguration.rootUrl = this._IAppEnvironment!.faceScanConfig!.faceScanBase!;
        this._WearablesApiConfiguration.rootUrl = this._IAppEnvironment!.wearableConfig!.wearableV3Base!;
        this._authenticationConfiguraiton.rootUrl = this._IAppEnvironment!.authenticateOptions!.authorizationServiceURL!;
        this._notificationService.rootUrl = this._IAppEnvironment!.notifyConfig!.notifyBase!;
        this._notificationProviderService.rootUrl = this._IAppEnvironment!.notifyConfig!.notifyBase!;
        this._virtualHealthLibraryConfiguration.rootUrl = this._IAppEnvironment!.virtualHealthLibraryConfig!.virtualHealthLibraryBase!;
        this._organizationsService.rootUrl = this._IAppEnvironment!.organizationConfig!.organizationBase!;
        this._mpiService.rootUrl = this._IAppEnvironment!.mpiConfig!.mpiBase!;
        this._pointService.rootUrl = this._IAppEnvironment!.pointsConfig!.pointsBase!;
        
      } catch (error) {
        setTimeout(async () => { await this.LoadConfigurationAsync(true); });
      }
      this._systemLogService.enableLogging();

    }
  }


  async OpenAppStore(): Promise<void> {
    var url = this._IAppEnvironment?.androidStoreLink!;
    if (Capacitor.getPlatform() === 'ios') {
      url = this._IAppEnvironment?.iosStoreLink!;
    }
    await Browser.open({ url: url });
  }

  public async EnsureNetworkConfiguration(source: string): Promise<ILocalAppEnvironment | null> {
    await this.LoadConfigurationAsync(false);
    this.setupNetworkLocations();
    return this._IAppEnvironment;
  }

  public async SaveEnvironmentConfig(config: ILocalAppEnvironment): Promise<void> {
    this._IAppEnvironment = config;
    await this._StorageService.set(StorageKey.EnvironmentSettings, JSON.stringify(config));
    this.setupNetworkLocations();
    this._EventService.environmentLoaded.emit(this._IAppEnvironment!);
  }

  public getCurrentConfig(): ILocalAppEnvironment | null {
    return this._IAppEnvironment;
  }

  public async GetCountries(): Promise<CountryItemModel[] | null | undefined> {
    if (this._CountryList && this._CountryList.length > 0)
      return this._CountryList;

    try {
      const networkCall = this._AppEnvironmentAppEnvironmentService.appEnvironmentCountriesGet();
      this._CountryList = await firstValueFrom(networkCall.pipe(timeout(this.NETWORK_TIMEOUT)));

      if(environment.countryCode && this._CountryList &&this._CountryList.length > 1){
        this._CountryList = this._CountryList.filter(a => a.countryCode!.toLowerCase() == (environment.countryCode! as string).toLowerCase());
      }



    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error, 'GetCountries');
    }

    return this._CountryList;
  }

  public async CurrentCountry(): Promise<CountryItemModel | null | undefined> {

    if (!this._SelectedRegion) {
      var currentCountry = await this._StorageService.get(SecureStorageKey.CurrentRegion);
      if (currentCountry) {
        this._SelectedRegion = JSON.parse(currentCountry);
      }
    }
    return this._SelectedRegion;
  }

  private lastEmailTest: string = "";
  private userCountries: CountryItemModel[] | null | undefined = null;
  public async GetCountriesForUser(emailAddress: string): Promise<CountryItemModel[] | null | undefined> {
    if (!emailAddress || emailAddress == this.lastEmailTest) {

      if (this.userCountries && this._Production) {
        var tempresult = this.userCountries;

        if(environment.countryCode && tempresult.length > 1){
          tempresult = tempresult.filter(a => a.countryCode!.toLowerCase() == (environment.countryCode! as string).toLowerCase());
        }
        return tempresult;
      }
    }

    try {
      var appInfo: AppInfo = {
        name: window.location.hostname,
        id: sharedConfig.webAppId!,
        version: sharedConfig.version,
        build: sharedConfig.build.toString(),
      }

      if (Capacitor.getPlatform() != 'web') {
        var appInfo = await App.getInfo();
      }

      const request = {
        deviceType: Capacitor.getPlatform(),
        appId: appInfo.id!,
        production: this._Production,
        emailAddress: emailAddress
      };

      // Await the result of the API call
      var result = await firstValueFrom(this._AppEnvironmentAppEnvironmentService.appEnvironmentCountriesForUserDeviceTypeAppIdProductionGet(request).pipe(timeout(this.NETWORK_TIMEOUT)));
      if (result) {
        this.lastEmailTest = emailAddress;

        if(environment.countryCode && result &&result.length > 1){
          result = result.filter(a => a.countryCode!.toLowerCase() == (environment.countryCode! as string).toLowerCase());
        }

        this._CountryList = result;

        if (this._Production == false) {
          this._CountryList = result.filter(a => a.countryCode === "za")
        }
        this.userCountries =  this._CountryList;

        if (this._CountryList.length == 1)
          await this.SetCountry(this._CountryList[0].countryCode!);
      }

      return this._CountryList;
    } catch (error) {
      // Log the error or handle it as needed
      await this._PromptService.showNetworkConnectionError(error, 'GetCountriesForUser');
      // You can return null, undefined, or any other value to indicate failure
      return null;
    }
  }

  public async SetCountry(countryCode: string): Promise<boolean> {
    if (!this._CountryList || this._CountryList.length == 0) {
      await this.GetCountries();
    }

    if (countryCode)
      countryCode = countryCode.toLowerCase();
    this._SelectedRegion = this._CountryList?.find(x => x.countryCode && x.countryCode.toLowerCase() == countryCode);
    if (this._SelectedRegion) {
      await this._StorageService.set(SecureStorageKey.CurrentRegion, JSON.stringify(this._SelectedRegion));
      await this.LoadConfigurationAsync(true);
      return true;
    }
    return false;
  }

  public async GetVivaScore(model: GetVivaScoreRequestModel): Promise<string | null> {
    try {
      return await firstValueFrom(this._AppEnvironmentAppEnvironmentService.appEnvironmentGetVivaScoreAsyncPost({ body: model }).pipe(timeout(this.NETWORK_TIMEOUT)));
    } catch (error) {
      // Log the error or handle it as needed
      await this._PromptService.showNetworkConnectionError(error, 'GetVivaScore');
      // You can return null, undefined, or any other value to indicate failure
      return null;
    }
  }

  public async initService(): Promise<void> {
    if (!this.initialized)
      await this.init();

    await this.LoadConfigurationAsync(false);
  }

  public async pingService(source: string): Promise<DevicePingResponse> {
    var result: DevicePingResponse = {
      networkSuccess: false,
      networkTime: 0,
      deviceInfoSuccess: false,
      pingError: null,
      pingResult: null
    };

    var personId: string | undefined | null = null;
    var region: string | undefined | null = null;
    var myPingRequest: VivaPingRequest | null = null;



    try {
      var currRegion = await this._StorageService.get(SecureStorageKey.CurrentRegion);
      if (currRegion) {
        var curR = JSON.parse(currRegion) as CountryItemModel;
        if (curR) {
          region = curR.countryCode;
        }
      }
    }
    catch {
      //no region
    }

    try {
      var currUser = await this._StorageService.get(SecureStorageKey.CurrentUser);
      if (currUser) {
        var usrmModel = JSON.parse(currUser) as UserData;
        if (usrmModel) {
          personId = usrmModel.tokenClaims.personId;
        }
      }
    } catch {

    }

    try {
      // Call all asynchronous functions concurrently
      const [deviceIdResult, deviceInfoResult, languageCodeResult, languageTagResult] = await Promise.all([
        Device.getId(),
        Device.getInfo(),
        Device.getLanguageCode(),
        Device.getLanguageTag(),
      ]);

      myPingRequest = {
        androidSDKVersion: deviceInfoResult.androidSDKVersion,
        deviceTime: new Date().toISOString(),
        identifier: deviceIdResult.identifier,
        iosVersion: deviceInfoResult.iOSVersion,
        isVirtual: deviceInfoResult.isVirtual,
        languageCode: languageCodeResult.value,
        languageValue: languageTagResult.value,
        manufacturer: deviceInfoResult.manufacturer,
        memoryUsed: deviceInfoResult.memUsed,
        model: deviceInfoResult.model,
        name: deviceInfoResult.name,
        operatingSystem: deviceInfoResult.operatingSystem,
        osVersion: deviceInfoResult.osVersion,
        personId: personId,
        platform: deviceInfoResult.platform,
        region: region,
        vivaAppBuild: sharedConfig.build,
        vivaAppVersion: sharedConfig.version,
        webViewVersion: deviceInfoResult.webViewVersion,
        pingPath: source
      };

      result.deviceInfoSuccess = true;
    } catch (error) {
      result.deviceInfoSuccess = false;
    }

    if (result.deviceInfoSuccess) {

      try {
        // Apply a timeout of 10 seconds to the network call
        var pingResult = await firstValueFrom(
          this._AppEnvironmentAppEnvironmentService
            .appEnvironmentVivaScorePingPost({ body: myPingRequest! })
            .pipe(timeout(this.PING_TIMEOUT))  // 5 seconds timeout
        );
        result.networkSuccess = true;
        result.pingResult = pingResult;
      } catch (error) {
        result.networkSuccess = false;
        if (error instanceof Error) {
          result.pingError = error.name === 'TimeoutError' ? 'Timeout' : error.message;
        } else {
          result.pingError = "Gerenic Error";
        }
      }
    } else {
      result.networkSuccess = false;
      result.pingError = result.pingError = 'No connection'
    }


    return result;
  }
}

export interface DevicePingResponse {
  networkSuccess: boolean;
  networkTime: number;
  deviceInfoSuccess: boolean;
  pingError: any | null;
  pingResult: string | null;
}

export interface ILocalAppEnvironment extends IAppEnvironment {
  build?: string | null;
  deviceType?: string | null;
  id?: string | null;
  name?: string | null;
  version?: string | null;
  installSource?: string | null;
  production: boolean
}
