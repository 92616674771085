/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { WearablesBaseService } from '../wearables-base-service';
import { WearablesApiConfiguration } from '../wearables-api-configuration';
import { WearablesStrictHttpResponse } from '../wearables-strict-http-response';

import { DataAvailableResponseModel } from '../models/data-available-response-model';
import { HealthScoreResponseModel } from '../models/health-score-response-model';
import { integrationDataAvailablePersonIdPost } from '../fn/integration/integration-data-available-person-id-post';
import { IntegrationDataAvailablePersonIdPost$Params } from '../fn/integration/integration-data-available-person-id-post';
import { IntegrationDataResponse } from '../models/integration-data-response';
import { integrationHealthScoreMetricsPersonIdPost } from '../fn/integration/integration-health-score-metrics-person-id-post';
import { IntegrationHealthScoreMetricsPersonIdPost$Params } from '../fn/integration/integration-health-score-metrics-person-id-post';
import { integrationPersonRegisteredPersonIdGet } from '../fn/integration/integration-person-registered-person-id-get';
import { IntegrationPersonRegisteredPersonIdGet$Params } from '../fn/integration/integration-person-registered-person-id-get';
import { integrationRetrieveDataPersonIdPost } from '../fn/integration/integration-retrieve-data-person-id-post';
import { IntegrationRetrieveDataPersonIdPost$Params } from '../fn/integration/integration-retrieve-data-person-id-post';

@Injectable({ providedIn: 'root' })
export class WearablesIntegrationService extends WearablesBaseService {
  constructor(config: WearablesApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `integrationPersonRegisteredPersonIdGet()` */
  static readonly IntegrationPersonRegisteredPersonIdGetPath = '/Integration/PersonRegistered/{personId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `integrationPersonRegisteredPersonIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  integrationPersonRegisteredPersonIdGet$Response(params: IntegrationPersonRegisteredPersonIdGet$Params, context?: HttpContext): Observable<WearablesStrictHttpResponse<boolean>> {
    return integrationPersonRegisteredPersonIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `integrationPersonRegisteredPersonIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  integrationPersonRegisteredPersonIdGet(params: IntegrationPersonRegisteredPersonIdGet$Params, context?: HttpContext): Observable<boolean> {
    return this.integrationPersonRegisteredPersonIdGet$Response(params, context).pipe(
      map((r: WearablesStrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `integrationDataAvailablePersonIdPost()` */
  static readonly IntegrationDataAvailablePersonIdPostPath = '/Integration/DataAvailable/{personId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `integrationDataAvailablePersonIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  integrationDataAvailablePersonIdPost$Response(params: IntegrationDataAvailablePersonIdPost$Params, context?: HttpContext): Observable<WearablesStrictHttpResponse<DataAvailableResponseModel>> {
    return integrationDataAvailablePersonIdPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `integrationDataAvailablePersonIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  integrationDataAvailablePersonIdPost(params: IntegrationDataAvailablePersonIdPost$Params, context?: HttpContext): Observable<DataAvailableResponseModel> {
    return this.integrationDataAvailablePersonIdPost$Response(params, context).pipe(
      map((r: WearablesStrictHttpResponse<DataAvailableResponseModel>): DataAvailableResponseModel => r.body)
    );
  }

  /** Path part for operation `integrationRetrieveDataPersonIdPost()` */
  static readonly IntegrationRetrieveDataPersonIdPostPath = '/Integration/RetrieveData/{personId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `integrationRetrieveDataPersonIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  integrationRetrieveDataPersonIdPost$Response(params: IntegrationRetrieveDataPersonIdPost$Params, context?: HttpContext): Observable<WearablesStrictHttpResponse<IntegrationDataResponse>> {
    return integrationRetrieveDataPersonIdPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `integrationRetrieveDataPersonIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  integrationRetrieveDataPersonIdPost(params: IntegrationRetrieveDataPersonIdPost$Params, context?: HttpContext): Observable<IntegrationDataResponse> {
    return this.integrationRetrieveDataPersonIdPost$Response(params, context).pipe(
      map((r: WearablesStrictHttpResponse<IntegrationDataResponse>): IntegrationDataResponse => r.body)
    );
  }

  /** Path part for operation `integrationHealthScoreMetricsPersonIdPost()` */
  static readonly IntegrationHealthScoreMetricsPersonIdPostPath = '/Integration/HealthScoreMetrics/{personId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `integrationHealthScoreMetricsPersonIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  integrationHealthScoreMetricsPersonIdPost$Response(params: IntegrationHealthScoreMetricsPersonIdPost$Params, context?: HttpContext): Observable<WearablesStrictHttpResponse<HealthScoreResponseModel>> {
    return integrationHealthScoreMetricsPersonIdPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `integrationHealthScoreMetricsPersonIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  integrationHealthScoreMetricsPersonIdPost(params: IntegrationHealthScoreMetricsPersonIdPost$Params, context?: HttpContext): Observable<HealthScoreResponseModel> {
    return this.integrationHealthScoreMetricsPersonIdPost$Response(params, context).pipe(
      map((r: WearablesStrictHttpResponse<HealthScoreResponseModel>): HealthScoreResponseModel => r.body)
    );
  }

}
