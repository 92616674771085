/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FaceScanBaseService } from '../face-scan-base-service';
import { FaceScanApiConfiguration } from '../face-scan-api-configuration';
import { FaceScanStrictHttpResponse } from '../face-scan-strict-http-response';

import { IntentConfigModel } from '../models/intent-config-model';
import { MeasurementModel } from '../models/measurement-model';
import { ProfileReportModel } from '../models/profile-report-model';
import { ProfileResponseModel } from '../models/profile-response-model';
import { ProfileScanHistoryModel } from '../models/profile-scan-history-model';
import { scanBiometricHistoryProfileIdGet } from '../fn/scan/scan-biometric-history-profile-id-get';
import { ScanBiometricHistoryProfileIdGet$Params } from '../fn/scan/scan-biometric-history-profile-id-get';
import { scanHistoryGet } from '../fn/scan/scan-history-get';
import { ScanHistoryGet$Params } from '../fn/scan/scan-history-get';
import { scanIntentScanConfigSessionIdGet } from '../fn/scan/scan-intent-scan-config-session-id-get';
import { ScanIntentScanConfigSessionIdGet$Params } from '../fn/scan/scan-intent-scan-config-session-id-get';
import { scanLastScanProfileIdGet } from '../fn/scan/scan-last-scan-profile-id-get';
import { ScanLastScanProfileIdGet$Params } from '../fn/scan/scan-last-scan-profile-id-get';
import { scanProfileProfileIdGet } from '../fn/scan/scan-profile-profile-id-get';
import { ScanProfileProfileIdGet$Params } from '../fn/scan/scan-profile-profile-id-get';
import { scanRequestSessionPost } from '../fn/scan/scan-request-session-post';
import { ScanRequestSessionPost$Params } from '../fn/scan/scan-request-session-post';
import { scanScanCompletedSessionIdGet } from '../fn/scan/scan-scan-completed-session-id-get';
import { ScanScanCompletedSessionIdGet$Params } from '../fn/scan/scan-scan-completed-session-id-get';
import { scanScanReportProfileIdPost } from '../fn/scan/scan-scan-report-profile-id-post';
import { ScanScanReportProfileIdPost$Params } from '../fn/scan/scan-scan-report-profile-id-post';
import { scanSdkConfigGet } from '../fn/scan/scan-sdk-config-get';
import { ScanSdkConfigGet$Params } from '../fn/scan/scan-sdk-config-get';
import { scanSdkScanConfigAsyncPost } from '../fn/scan/scan-sdk-scan-config-async-post';
import { ScanSdkScanConfigAsyncPost$Params } from '../fn/scan/scan-sdk-scan-config-async-post';
import { scanSessionHistoryProfileIdGet } from '../fn/scan/scan-session-history-profile-id-get';
import { ScanSessionHistoryProfileIdGet$Params } from '../fn/scan/scan-session-history-profile-id-get';
import { ScanSessionResultModel } from '../models/scan-session-result-model';
import { scanSessionResultSessionIdGet } from '../fn/scan/scan-session-result-session-id-get';
import { ScanSessionResultSessionIdGet$Params } from '../fn/scan/scan-session-result-session-id-get';
import { scanSignalSetupGet } from '../fn/scan/scan-signal-setup-get';
import { ScanSignalSetupGet$Params } from '../fn/scan/scan-signal-setup-get';
import { scanSubmitSdkSessionPost } from '../fn/scan/scan-submit-sdk-session-post';
import { ScanSubmitSdkSessionPost$Params } from '../fn/scan/scan-submit-sdk-session-post';
import { SdkConfigModel } from '../models/sdk-config-model';
import { SessionResponseModel } from '../models/session-response-model';

@Injectable({ providedIn: 'root' })
export class FaceScanScanService extends FaceScanBaseService {
  constructor(config: FaceScanApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `scanRequestSessionPost()` */
  static readonly ScanRequestSessionPostPath = '/Scan/RequestSession';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scanRequestSessionPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  scanRequestSessionPost$Response(params?: ScanRequestSessionPost$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<SessionResponseModel>> {
    return scanRequestSessionPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scanRequestSessionPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  scanRequestSessionPost(params?: ScanRequestSessionPost$Params, context?: HttpContext): Observable<SessionResponseModel> {
    return this.scanRequestSessionPost$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<SessionResponseModel>): SessionResponseModel => r.body)
    );
  }

  /** Path part for operation `scanProfileProfileIdGet()` */
  static readonly ScanProfileProfileIdGetPath = '/Scan/Profile/{profileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scanProfileProfileIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  scanProfileProfileIdGet$Response(params: ScanProfileProfileIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<ProfileResponseModel>> {
    return scanProfileProfileIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scanProfileProfileIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  scanProfileProfileIdGet(params: ScanProfileProfileIdGet$Params, context?: HttpContext): Observable<ProfileResponseModel> {
    return this.scanProfileProfileIdGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<ProfileResponseModel>): ProfileResponseModel => r.body)
    );
  }

  /** Path part for operation `scanSessionResultSessionIdGet()` */
  static readonly ScanSessionResultSessionIdGetPath = '/Scan/SessionResult/{sessionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scanSessionResultSessionIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  scanSessionResultSessionIdGet$Response(params: ScanSessionResultSessionIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<ScanSessionResultModel>> {
    return scanSessionResultSessionIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scanSessionResultSessionIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  scanSessionResultSessionIdGet(params: ScanSessionResultSessionIdGet$Params, context?: HttpContext): Observable<ScanSessionResultModel> {
    return this.scanSessionResultSessionIdGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<ScanSessionResultModel>): ScanSessionResultModel => r.body)
    );
  }

  /** Path part for operation `scanSessionHistoryProfileIdGet()` */
  static readonly ScanSessionHistoryProfileIdGetPath = '/Scan/SessionHistory/{profileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scanSessionHistoryProfileIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  scanSessionHistoryProfileIdGet$Response(params: ScanSessionHistoryProfileIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<Array<ScanSessionResultModel>>> {
    return scanSessionHistoryProfileIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scanSessionHistoryProfileIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  scanSessionHistoryProfileIdGet(params: ScanSessionHistoryProfileIdGet$Params, context?: HttpContext): Observable<Array<ScanSessionResultModel>> {
    return this.scanSessionHistoryProfileIdGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<Array<ScanSessionResultModel>>): Array<ScanSessionResultModel> => r.body)
    );
  }

  /** Path part for operation `scanHistoryGet()` */
  static readonly ScanHistoryGetPath = '/Scan/History';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scanHistoryGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  scanHistoryGet$Response(params?: ScanHistoryGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<Array<ScanSessionResultModel>>> {
    return scanHistoryGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scanHistoryGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  scanHistoryGet(params?: ScanHistoryGet$Params, context?: HttpContext): Observable<Array<ScanSessionResultModel>> {
    return this.scanHistoryGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<Array<ScanSessionResultModel>>): Array<ScanSessionResultModel> => r.body)
    );
  }

  /** Path part for operation `scanSubmitSdkSessionPost()` */
  static readonly ScanSubmitSdkSessionPostPath = '/Scan/SubmitSDKSession';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scanSubmitSdkSessionPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  scanSubmitSdkSessionPost$Response(params?: ScanSubmitSdkSessionPost$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<ScanSessionResultModel>> {
    return scanSubmitSdkSessionPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scanSubmitSdkSessionPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  scanSubmitSdkSessionPost(params?: ScanSubmitSdkSessionPost$Params, context?: HttpContext): Observable<ScanSessionResultModel> {
    return this.scanSubmitSdkSessionPost$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<ScanSessionResultModel>): ScanSessionResultModel => r.body)
    );
  }

  /** Path part for operation `scanSdkScanConfigAsyncPost()` */
  static readonly ScanSdkScanConfigAsyncPostPath = '/Scan/SDKScanConfigAsync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scanSdkScanConfigAsyncPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  scanSdkScanConfigAsyncPost$Response(params?: ScanSdkScanConfigAsyncPost$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<SdkConfigModel>> {
    return scanSdkScanConfigAsyncPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scanSdkScanConfigAsyncPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  scanSdkScanConfigAsyncPost(params?: ScanSdkScanConfigAsyncPost$Params, context?: HttpContext): Observable<SdkConfigModel> {
    return this.scanSdkScanConfigAsyncPost$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<SdkConfigModel>): SdkConfigModel => r.body)
    );
  }

  /** Path part for operation `scanSdkConfigGet()` */
  static readonly ScanSdkConfigGetPath = '/Scan/SDKConfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scanSdkConfigGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  scanSdkConfigGet$Response(params?: ScanSdkConfigGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<SdkConfigModel>> {
    return scanSdkConfigGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scanSdkConfigGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  scanSdkConfigGet(params?: ScanSdkConfigGet$Params, context?: HttpContext): Observable<SdkConfigModel> {
    return this.scanSdkConfigGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<SdkConfigModel>): SdkConfigModel => r.body)
    );
  }

  /** Path part for operation `scanSignalSetupGet()` */
  static readonly ScanSignalSetupGetPath = '/Scan/SignalSetup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scanSignalSetupGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  scanSignalSetupGet$Response(params?: ScanSignalSetupGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<Array<MeasurementModel>>> {
    return scanSignalSetupGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scanSignalSetupGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  scanSignalSetupGet(params?: ScanSignalSetupGet$Params, context?: HttpContext): Observable<Array<MeasurementModel>> {
    return this.scanSignalSetupGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<Array<MeasurementModel>>): Array<MeasurementModel> => r.body)
    );
  }

  /** Path part for operation `scanLastScanProfileIdGet()` */
  static readonly ScanLastScanProfileIdGetPath = '/Scan/LastScan/{profileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scanLastScanProfileIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  scanLastScanProfileIdGet$Response(params: ScanLastScanProfileIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<ScanSessionResultModel>> {
    return scanLastScanProfileIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scanLastScanProfileIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  scanLastScanProfileIdGet(params: ScanLastScanProfileIdGet$Params, context?: HttpContext): Observable<ScanSessionResultModel> {
    return this.scanLastScanProfileIdGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<ScanSessionResultModel>): ScanSessionResultModel => r.body)
    );
  }

  /** Path part for operation `scanScanReportProfileIdPost()` */
  static readonly ScanScanReportProfileIdPostPath = '/Scan/ScanReport/{profileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scanScanReportProfileIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  scanScanReportProfileIdPost$Response(params: ScanScanReportProfileIdPost$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<ProfileReportModel>> {
    return scanScanReportProfileIdPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scanScanReportProfileIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  scanScanReportProfileIdPost(params: ScanScanReportProfileIdPost$Params, context?: HttpContext): Observable<ProfileReportModel> {
    return this.scanScanReportProfileIdPost$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<ProfileReportModel>): ProfileReportModel => r.body)
    );
  }

  /** Path part for operation `scanScanCompletedSessionIdGet()` */
  static readonly ScanScanCompletedSessionIdGetPath = '/Scan/ScanCompleted/{sessionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scanScanCompletedSessionIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  scanScanCompletedSessionIdGet$Response(params: ScanScanCompletedSessionIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<boolean>> {
    return scanScanCompletedSessionIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scanScanCompletedSessionIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  scanScanCompletedSessionIdGet(params: ScanScanCompletedSessionIdGet$Params, context?: HttpContext): Observable<boolean> {
    return this.scanScanCompletedSessionIdGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `scanIntentScanConfigSessionIdGet()` */
  static readonly ScanIntentScanConfigSessionIdGetPath = '/Scan/IntentScanConfig/{sessionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scanIntentScanConfigSessionIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  scanIntentScanConfigSessionIdGet$Response(params: ScanIntentScanConfigSessionIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<IntentConfigModel>> {
    return scanIntentScanConfigSessionIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scanIntentScanConfigSessionIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  scanIntentScanConfigSessionIdGet(params: ScanIntentScanConfigSessionIdGet$Params, context?: HttpContext): Observable<IntentConfigModel> {
    return this.scanIntentScanConfigSessionIdGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<IntentConfigModel>): IntentConfigModel => r.body)
    );
  }

  /** Path part for operation `scanBiometricHistoryProfileIdGet()` */
  static readonly ScanBiometricHistoryProfileIdGetPath = '/Scan/BiometricHistory/{profileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scanBiometricHistoryProfileIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  scanBiometricHistoryProfileIdGet$Response(params: ScanBiometricHistoryProfileIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<ProfileScanHistoryModel>> {
    return scanBiometricHistoryProfileIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scanBiometricHistoryProfileIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  scanBiometricHistoryProfileIdGet(params: ScanBiometricHistoryProfileIdGet$Params, context?: HttpContext): Observable<ProfileScanHistoryModel> {
    return this.scanBiometricHistoryProfileIdGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<ProfileScanHistoryModel>): ProfileScanHistoryModel => r.body)
    );
  }

}
