/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FaceScanStrictHttpResponse } from '../../face-scan-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ScanSessionResultModel } from '../../models/scan-session-result-model';

export interface ScanHistoryGet$Params {
  pageNumber?: number;
  fromDate?: string;
  toDate?: string;
}

export function scanHistoryGet(http: HttpClient, rootUrl: string, params?: ScanHistoryGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<Array<ScanSessionResultModel>>> {
  const rb = new RequestBuilder(rootUrl, scanHistoryGet.PATH, 'get');
  if (params) {
    rb.query('pageNumber', params.pageNumber, {});
    rb.query('fromDate', params.fromDate, {});
    rb.query('toDate', params.toDate, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as FaceScanStrictHttpResponse<Array<ScanSessionResultModel>>;
    })
  );
}

scanHistoryGet.PATH = '/Scan/History';
