/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthenticationStrictHttpResponse } from '../../authentication-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResetPasswordRequestModel } from '../../models/reset-password-request-model';

export interface LocalAccountResetForgotPasswordPost$Params {
      body?: ResetPasswordRequestModel
}

export function localAccountResetForgotPasswordPost(http: HttpClient, rootUrl: string, params?: LocalAccountResetForgotPasswordPost$Params, context?: HttpContext): Observable<AuthenticationStrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, localAccountResetForgotPasswordPost.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as AuthenticationStrictHttpResponse<void>;
    })
  );
}

localAccountResetForgotPasswordPost.PATH = '/LocalAccount/ResetForgotPassword';
