/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { VirtualHealthLibraryStrictHttpResponse } from '../../virtual-health-library-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TokenRequestModel } from '../../models/token-request-model';
import { TokenResponseModel } from '../../models/token-response-model';

export interface TokenAccessTokenPost$Params {
      body?: TokenRequestModel
}

export function tokenAccessTokenPost(http: HttpClient, rootUrl: string, params?: TokenAccessTokenPost$Params, context?: HttpContext): Observable<VirtualHealthLibraryStrictHttpResponse<TokenResponseModel>> {
  const rb = new RequestBuilder(rootUrl, tokenAccessTokenPost.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as VirtualHealthLibraryStrictHttpResponse<TokenResponseModel>;
    })
  );
}

tokenAccessTokenPost.PATH = '/Token/AccessToken';
