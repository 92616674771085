/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthenticationBaseService } from '../authentication-base-service';
import { AuthenticationApiConfiguration } from '../authentication-api-configuration';
import { AuthenticationStrictHttpResponse } from '../authentication-strict-http-response';

import { LoggedInUser } from '../models/logged-in-user';
import { userTokenGetUserDetailGet } from '../fn/user-token/user-token-get-user-detail-get';
import { UserTokenGetUserDetailGet$Params } from '../fn/user-token/user-token-get-user-detail-get';

@Injectable({ providedIn: 'root' })
export class AuthenticationUserTokenService extends AuthenticationBaseService {
  constructor(config: AuthenticationApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `userTokenGetUserDetailGet()` */
  static readonly UserTokenGetUserDetailGetPath = '/UserToken/GetUserDetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userTokenGetUserDetailGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  userTokenGetUserDetailGet$Response(params?: UserTokenGetUserDetailGet$Params, context?: HttpContext): Observable<AuthenticationStrictHttpResponse<LoggedInUser>> {
    return userTokenGetUserDetailGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userTokenGetUserDetailGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userTokenGetUserDetailGet(params?: UserTokenGetUserDetailGet$Params, context?: HttpContext): Observable<LoggedInUser> {
    return this.userTokenGetUserDetailGet$Response(params, context).pipe(
      map((r: AuthenticationStrictHttpResponse<LoggedInUser>): LoggedInUser => r.body)
    );
  }

}
