/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FaceScanBaseService } from '../face-scan-base-service';
import { FaceScanApiConfiguration } from '../face-scan-api-configuration';
import { FaceScanStrictHttpResponse } from '../face-scan-strict-http-response';

import { callbackImagesFileNameGet } from '../fn/callback/callback-images-file-name-get';
import { CallbackImagesFileNameGet$Params } from '../fn/callback/callback-images-file-name-get';
import { callbackMeasurementCompleteGet } from '../fn/callback/callback-measurement-complete-get';
import { CallbackMeasurementCompleteGet$Params } from '../fn/callback/callback-measurement-complete-get';
import { callbackStylesFileNameGet } from '../fn/callback/callback-styles-file-name-get';
import { CallbackStylesFileNameGet$Params } from '../fn/callback/callback-styles-file-name-get';
import { callbackStyleSheetFileNameGet } from '../fn/callback/callback-style-sheet-file-name-get';
import { CallbackStyleSheetFileNameGet$Params } from '../fn/callback/callback-style-sheet-file-name-get';
import { callbackWebhookPost } from '../fn/callback/callback-webhook-post';
import { CallbackWebhookPost$Params } from '../fn/callback/callback-webhook-post';

@Injectable({ providedIn: 'root' })
export class FaceScanCallbackService extends FaceScanBaseService {
  constructor(config: FaceScanApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `callbackMeasurementCompleteGet()` */
  static readonly CallbackMeasurementCompleteGetPath = '/Callback/MeasurementComplete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callbackMeasurementCompleteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  callbackMeasurementCompleteGet$Response(params?: CallbackMeasurementCompleteGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<void>> {
    return callbackMeasurementCompleteGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callbackMeasurementCompleteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  callbackMeasurementCompleteGet(params?: CallbackMeasurementCompleteGet$Params, context?: HttpContext): Observable<void> {
    return this.callbackMeasurementCompleteGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `callbackImagesFileNameGet()` */
  static readonly CallbackImagesFileNameGetPath = '/Callback/images/{fileName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callbackImagesFileNameGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  callbackImagesFileNameGet$Response(params: CallbackImagesFileNameGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<void>> {
    return callbackImagesFileNameGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callbackImagesFileNameGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  callbackImagesFileNameGet(params: CallbackImagesFileNameGet$Params, context?: HttpContext): Observable<void> {
    return this.callbackImagesFileNameGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `callbackStylesFileNameGet()` */
  static readonly CallbackStylesFileNameGetPath = '/Callback/styles/{fileName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callbackStylesFileNameGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  callbackStylesFileNameGet$Response(params: CallbackStylesFileNameGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<void>> {
    return callbackStylesFileNameGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callbackStylesFileNameGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  callbackStylesFileNameGet(params: CallbackStylesFileNameGet$Params, context?: HttpContext): Observable<void> {
    return this.callbackStylesFileNameGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `callbackStyleSheetFileNameGet()` */
  static readonly CallbackStyleSheetFileNameGetPath = '/Callback/StyleSheet/{fileName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callbackStyleSheetFileNameGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  callbackStyleSheetFileNameGet$Response(params: CallbackStyleSheetFileNameGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<void>> {
    return callbackStyleSheetFileNameGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callbackStyleSheetFileNameGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  callbackStyleSheetFileNameGet(params: CallbackStyleSheetFileNameGet$Params, context?: HttpContext): Observable<void> {
    return this.callbackStyleSheetFileNameGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `callbackWebhookPost()` */
  static readonly CallbackWebhookPostPath = '/Callback/webhook';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callbackWebhookPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  callbackWebhookPost$Response(params?: CallbackWebhookPost$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<void>> {
    return callbackWebhookPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callbackWebhookPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  callbackWebhookPost(params?: CallbackWebhookPost$Params, context?: HttpContext): Observable<void> {
    return this.callbackWebhookPost$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<void>): void => r.body)
    );
  }

}
