/* tslint:disable */
/* eslint-disable */
export enum AppAccessType {
  HealthScorePage = 'HealthScorePage',
  MyProfilePage = 'MyProfilePage',
  MediaPage = 'MediaPage',
  AppointmentPage = 'AppointmentPage',
  WearablesPage = 'WearablesPage',
  TimeSpent = 'TimeSpent',
  VitalScore = 'VitalScore',
  OrganisationPage = 'OrganisationPage',
  FaceScanPage = 'FaceScanPage'
}
