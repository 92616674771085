/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { VitalScoreStrictHttpResponse } from '../../vital-score-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AppLogModel } from '../../models/app-log-model';

export interface ProfileAppLogMessagePost$Params {
      body?: AppLogModel
}

export function profileAppLogMessagePost(http: HttpClient, rootUrl: string, params?: ProfileAppLogMessagePost$Params, context?: HttpContext): Observable<VitalScoreStrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, profileAppLogMessagePost.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as VitalScoreStrictHttpResponse<void>;
    })
  );
}

profileAppLogMessagePost.PATH = '/Profile/AppLogMessage';
