/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { WearablesStrictHttpResponse } from '../../wearables-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DashboardRequestModel } from '../../models/dashboard-request-model';
import { DashboardSleepItemModel } from '../../models/dashboard-sleep-item-model';

export interface DashboardSleepSummaryOrganizationIdPost$Params {
  organizationId: string;
      body?: DashboardRequestModel
}

export function dashboardSleepSummaryOrganizationIdPost(http: HttpClient, rootUrl: string, params: DashboardSleepSummaryOrganizationIdPost$Params, context?: HttpContext): Observable<WearablesStrictHttpResponse<Array<DashboardSleepItemModel>>> {
  const rb = new RequestBuilder(rootUrl, dashboardSleepSummaryOrganizationIdPost.PATH, 'post');
  if (params) {
    rb.path('organizationId', params.organizationId, {});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as WearablesStrictHttpResponse<Array<DashboardSleepItemModel>>;
    })
  );
}

dashboardSleepSummaryOrganizationIdPost.PATH = '/Dashboard/SleepSummary/{organizationId}';
