/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MPIStrictHttpResponse } from '../../mpi-strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface AdminPersonIdB2CPersonActivePut$Params {
  personId: string;
  active?: boolean;
}

export function adminPersonIdB2CPersonActivePut(http: HttpClient, rootUrl: string, params: AdminPersonIdB2CPersonActivePut$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<string>> {
  const rb = new RequestBuilder(rootUrl, adminPersonIdB2CPersonActivePut.PATH, 'put');
  if (params) {
    rb.path('personId', params.personId, {});
    rb.query('active', params.active, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as MPIStrictHttpResponse<string>;
    })
  );
}

adminPersonIdB2CPersonActivePut.PATH = '/Admin/{personId}/B2CPersonActive';
