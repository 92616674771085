/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FaceScanStrictHttpResponse } from '../../face-scan-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SearchRequestModel } from '../../models/search-request-model';
import { SystemIdentifierAccessCodeModelSearchResultModel } from '../../models/system-identifier-access-code-model-search-result-model';

export interface AdminSearchSystemIdentifierAccessCodePost$Params {
      body?: SearchRequestModel
}

export function adminSearchSystemIdentifierAccessCodePost(http: HttpClient, rootUrl: string, params?: AdminSearchSystemIdentifierAccessCodePost$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<SystemIdentifierAccessCodeModelSearchResultModel>> {
  const rb = new RequestBuilder(rootUrl, adminSearchSystemIdentifierAccessCodePost.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as FaceScanStrictHttpResponse<SystemIdentifierAccessCodeModelSearchResultModel>;
    })
  );
}

adminSearchSystemIdentifierAccessCodePost.PATH = '/Admin/SearchSystemIdentifierAccessCode';
