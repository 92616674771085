/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OrganizationApiConfiguration, OrganizationApiConfigurationParams } from './organization-api-configuration';

import { OrganizationAdminService } from './services/organization-admin.service';
import { OrganizationConsumerService } from './services/organization-consumer.service';
import { OrganizationMonitorService } from './services/organization-monitor.service';
import { OrganizationReportService } from './services/organization-report.service';
import { OrganizationSyncService } from './services/organization-sync.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    OrganizationAdminService,
    OrganizationConsumerService,
    OrganizationMonitorService,
    OrganizationReportService,
    OrganizationSyncService,
    OrganizationApiConfiguration
  ],
})
export class OrganizationModule {
  static forRoot(params: OrganizationApiConfigurationParams): ModuleWithProviders<OrganizationModule> {
    return {
      ngModule: OrganizationModule,
      providers: [
        {
          provide: OrganizationApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: OrganizationModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('OrganizationModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
