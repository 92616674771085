/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppEnvironmentStrictHttpResponse } from '../../app-environment-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { IAppEnvironment } from '../../models/i-app-environment';

export interface AppEnvironmentEnvironmentSetupEnvironmentNameCountryCodeDeviceTypeNameAppIdVersionBuildProductionGet$Params {
  environmentName: string;
  countryCode: string;
  deviceType: string;
  name: string;
  appId: string;
  version: string;
  build: number;
  production: boolean;
}

export function appEnvironmentEnvironmentSetupEnvironmentNameCountryCodeDeviceTypeNameAppIdVersionBuildProductionGet(http: HttpClient, rootUrl: string, params: AppEnvironmentEnvironmentSetupEnvironmentNameCountryCodeDeviceTypeNameAppIdVersionBuildProductionGet$Params, context?: HttpContext): Observable<AppEnvironmentStrictHttpResponse<IAppEnvironment>> {
  const rb = new RequestBuilder(rootUrl, appEnvironmentEnvironmentSetupEnvironmentNameCountryCodeDeviceTypeNameAppIdVersionBuildProductionGet.PATH, 'get');
  if (params) {
    rb.path('environmentName', params.environmentName, {});
    rb.path('countryCode', params.countryCode, {});
    rb.path('deviceType', params.deviceType, {});
    rb.path('name', params.name, {});
    rb.path('appId', params.appId, {});
    rb.path('version', params.version, {});
    rb.path('build', params.build, {});
    rb.path('production', params.production, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as AppEnvironmentStrictHttpResponse<IAppEnvironment>;
    })
  );
}

appEnvironmentEnvironmentSetupEnvironmentNameCountryCodeDeviceTypeNameAppIdVersionBuildProductionGet.PATH = '/AppEnvironment/EnvironmentSetup/{environmentName}/{countryCode}/{deviceType}/{name}/{appId}/{version}/{build}/{production}';
