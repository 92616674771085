import { Component, Input, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'viva-spinner',
  templateUrl: './viva-spinner.component.html',
  styleUrls: ['./viva-spinner.component.scss'],
})
export class VivaSpinnerComponent implements OnInit, AfterViewInit {

  @Input() width: string = '50px'; // Default width
  @Input() color: string = 'var(--ion-color-secondary)'; // Default color

  @ViewChild('animatedPath', { static: true }) animatedPath!: ElementRef<SVGPathElement>;
  @Input() animationDuration: number = 1500; // Total animation duration in milliseconds

  private fullPath: string = 'M30.5137 282.496H39.6618H48.33H57.1998H68.6902H78.568H87.4378H100.138H108.604H121.506H129.569H138.238H146.503H153.558H161.329H169.1L171.607 288.562L174.538 295.656L177.067 301.777L179.813 308.425L183.041 316.238L186.345 324.234L190.461 334.195L194.736 344.542L200.011 357.311L206.271 372.461L212.162 386.72L216.793 397.928L222.299 411.254L230.015 429.931L236.949 446.713L243.368 462.248L249.158 476.264L256.05 492.945L260.912 504.71L265.4 515.574L269.343 525.118L273.287 534.663L276.193 524.768L279.087 514.911L282.184 504.367L285.405 493.397L288.511 482.82L291.976 471.021L295.705 458.327L299.202 446.418L302.558 434.99L305.577 424.708L309.087 412.756L311.809 403.488L315.47 391.023L318.489 380.741L322.07 368.549L325.573 356.618L328.743 345.824L332.09 334.428L335.343 323.349L338.831 311.473L342.162 300.132L345.931 287.296L348.156 279.721L350.445 271.927L353.576 261.264L356.375 251.732L359.548 240.93L362.862 229.645L365.654 220.137L369.223 207.984L374.031 191.613L376.594 182.883L380.002 171.279L383.343 159.903L387.167 146.883L391.036 133.706L395.122 119.792L398.944 106.779L403.299 91.9476L406.813 79.9831L409.805 69.7955L413.369 57.6586L416.145 48.2081L418.747 39.3462L421.35 30.4843L424.802 38.8446L428.51 47.8246L432.657 57.8692L436.431 67.0093L440.366 76.537L444.655 86.9242L448.642 96.5811L453.549 108.466L459.864 123.757L464.966 136.113L469.911 148.09L474.348 158.834L478.389 168.62L482.656 178.954L487.256 190.094L490.612 198.223L494.342 207.256L501.221 223.916L507.633 239.443L512.832 252.036L516.154 260.08L519.68 268.619L522.528 275.517L525.376 282.414H533.16H543.445H555.847H569.308H582.315H595.776H606.968H621.337H632.075H646.443H657.635H669.584H679.968H690.351';
  private pathSegments: string[] = [];
  private startTime: number | null = null;

  constructor() { }

  ngOnInit() {}

  ngAfterViewInit() {
    this.pathSegments = this.splitPath(this.fullPath, 100); // Split path into segments
    this.animatePath();
  }

  private splitPath(path: string, segments: number): string[] {
    const commands = path.match(/[a-z][^a-z]*/ig) || [];
    const totalLength = commands.length;
    const segmentLength = Math.ceil(totalLength / segments);
    const pathSegments: string[] = [];

    for (let i = 0; i < totalLength; i += segmentLength) {
      pathSegments.push(commands.slice(0, i + segmentLength).join(' '));
    }

    return pathSegments;
  }

  private animatePath(timestamp?: number) {
    if (!this.startTime) {
      this.startTime = timestamp!;
    }

    const elapsed = timestamp! - this.startTime;
    const progress = elapsed / this.animationDuration;

    const segmentIndex = Math.floor(progress * this.pathSegments.length);
    const pathElement = this.animatedPath.nativeElement;

    if (segmentIndex < this.pathSegments.length) {
      pathElement.setAttribute('d', this.pathSegments[segmentIndex]);
      requestAnimationFrame(this.animatePath.bind(this));
    } else {
      // Reset and restart the animation
      this.startTime = null;
      pathElement.setAttribute('d', '');
      requestAnimationFrame(this.animatePath.bind(this));
    }
  }
}
