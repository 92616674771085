/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FaceScanBaseService } from '../face-scan-base-service';
import { FaceScanApiConfiguration } from '../face-scan-api-configuration';
import { FaceScanStrictHttpResponse } from '../face-scan-strict-http-response';

import { DataAvailableResponseModel } from '../models/data-available-response-model';
import { integrationDataAvailablePersonIdPost } from '../fn/integration/integration-data-available-person-id-post';
import { IntegrationDataAvailablePersonIdPost$Params } from '../fn/integration/integration-data-available-person-id-post';
import { IntegrationDataResponse } from '../models/integration-data-response';
import { integrationMemberReportSystemIdentifierIdPersonIdPost } from '../fn/integration/integration-member-report-system-identifier-id-person-id-post';
import { IntegrationMemberReportSystemIdentifierIdPersonIdPost$Params } from '../fn/integration/integration-member-report-system-identifier-id-person-id-post';
import { integrationOrganizationStatisticsSystemIdentifierIdOrganizationIdGet } from '../fn/integration/integration-organization-statistics-system-identifier-id-organization-id-get';
import { IntegrationOrganizationStatisticsSystemIdentifierIdOrganizationIdGet$Params } from '../fn/integration/integration-organization-statistics-system-identifier-id-organization-id-get';
import { integrationPersonRegisteredPersonIdGet } from '../fn/integration/integration-person-registered-person-id-get';
import { IntegrationPersonRegisteredPersonIdGet$Params } from '../fn/integration/integration-person-registered-person-id-get';
import { integrationRetrieveDataPersonIdPost } from '../fn/integration/integration-retrieve-data-person-id-post';
import { IntegrationRetrieveDataPersonIdPost$Params } from '../fn/integration/integration-retrieve-data-person-id-post';
import { integrationWeeklyStatisticsSystemIdentifierIdGet } from '../fn/integration/integration-weekly-statistics-system-identifier-id-get';
import { IntegrationWeeklyStatisticsSystemIdentifierIdGet$Params } from '../fn/integration/integration-weekly-statistics-system-identifier-id-get';
import { MemberReportModel } from '../models/member-report-model';
import { OrganizationStatisticsModel } from '../models/organization-statistics-model';
import { WeeklyStatisticModel } from '../models/weekly-statistic-model';

@Injectable({ providedIn: 'root' })
export class FaceScanIntegrationService extends FaceScanBaseService {
  constructor(config: FaceScanApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `integrationPersonRegisteredPersonIdGet()` */
  static readonly IntegrationPersonRegisteredPersonIdGetPath = '/Integration/PersonRegistered/{personId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `integrationPersonRegisteredPersonIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  integrationPersonRegisteredPersonIdGet$Response(params: IntegrationPersonRegisteredPersonIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<boolean>> {
    return integrationPersonRegisteredPersonIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `integrationPersonRegisteredPersonIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  integrationPersonRegisteredPersonIdGet(params: IntegrationPersonRegisteredPersonIdGet$Params, context?: HttpContext): Observable<boolean> {
    return this.integrationPersonRegisteredPersonIdGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `integrationDataAvailablePersonIdPost()` */
  static readonly IntegrationDataAvailablePersonIdPostPath = '/Integration/DataAvailable/{personId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `integrationDataAvailablePersonIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  integrationDataAvailablePersonIdPost$Response(params: IntegrationDataAvailablePersonIdPost$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<DataAvailableResponseModel>> {
    return integrationDataAvailablePersonIdPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `integrationDataAvailablePersonIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  integrationDataAvailablePersonIdPost(params: IntegrationDataAvailablePersonIdPost$Params, context?: HttpContext): Observable<DataAvailableResponseModel> {
    return this.integrationDataAvailablePersonIdPost$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<DataAvailableResponseModel>): DataAvailableResponseModel => r.body)
    );
  }

  /** Path part for operation `integrationRetrieveDataPersonIdPost()` */
  static readonly IntegrationRetrieveDataPersonIdPostPath = '/Integration/RetrieveData/{personId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `integrationRetrieveDataPersonIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  integrationRetrieveDataPersonIdPost$Response(params: IntegrationRetrieveDataPersonIdPost$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<IntegrationDataResponse>> {
    return integrationRetrieveDataPersonIdPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `integrationRetrieveDataPersonIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  integrationRetrieveDataPersonIdPost(params: IntegrationRetrieveDataPersonIdPost$Params, context?: HttpContext): Observable<IntegrationDataResponse> {
    return this.integrationRetrieveDataPersonIdPost$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<IntegrationDataResponse>): IntegrationDataResponse => r.body)
    );
  }

  /** Path part for operation `integrationMemberReportSystemIdentifierIdPersonIdPost()` */
  static readonly IntegrationMemberReportSystemIdentifierIdPersonIdPostPath = '/Integration/MemberReport/{systemIdentifierId}/{personId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `integrationMemberReportSystemIdentifierIdPersonIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  integrationMemberReportSystemIdentifierIdPersonIdPost$Response(params: IntegrationMemberReportSystemIdentifierIdPersonIdPost$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<MemberReportModel>> {
    return integrationMemberReportSystemIdentifierIdPersonIdPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `integrationMemberReportSystemIdentifierIdPersonIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  integrationMemberReportSystemIdentifierIdPersonIdPost(params: IntegrationMemberReportSystemIdentifierIdPersonIdPost$Params, context?: HttpContext): Observable<MemberReportModel> {
    return this.integrationMemberReportSystemIdentifierIdPersonIdPost$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<MemberReportModel>): MemberReportModel => r.body)
    );
  }

  /** Path part for operation `integrationWeeklyStatisticsSystemIdentifierIdGet()` */
  static readonly IntegrationWeeklyStatisticsSystemIdentifierIdGetPath = '/Integration/WeeklyStatistics/{systemIdentifierId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `integrationWeeklyStatisticsSystemIdentifierIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  integrationWeeklyStatisticsSystemIdentifierIdGet$Response(params: IntegrationWeeklyStatisticsSystemIdentifierIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<Array<WeeklyStatisticModel>>> {
    return integrationWeeklyStatisticsSystemIdentifierIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `integrationWeeklyStatisticsSystemIdentifierIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  integrationWeeklyStatisticsSystemIdentifierIdGet(params: IntegrationWeeklyStatisticsSystemIdentifierIdGet$Params, context?: HttpContext): Observable<Array<WeeklyStatisticModel>> {
    return this.integrationWeeklyStatisticsSystemIdentifierIdGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<Array<WeeklyStatisticModel>>): Array<WeeklyStatisticModel> => r.body)
    );
  }

  /** Path part for operation `integrationOrganizationStatisticsSystemIdentifierIdOrganizationIdGet()` */
  static readonly IntegrationOrganizationStatisticsSystemIdentifierIdOrganizationIdGetPath = '/Integration/OrganizationStatistics/{systemIdentifierId}/{organizationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `integrationOrganizationStatisticsSystemIdentifierIdOrganizationIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  integrationOrganizationStatisticsSystemIdentifierIdOrganizationIdGet$Response(params: IntegrationOrganizationStatisticsSystemIdentifierIdOrganizationIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<Array<OrganizationStatisticsModel>>> {
    return integrationOrganizationStatisticsSystemIdentifierIdOrganizationIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `integrationOrganizationStatisticsSystemIdentifierIdOrganizationIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  integrationOrganizationStatisticsSystemIdentifierIdOrganizationIdGet(params: IntegrationOrganizationStatisticsSystemIdentifierIdOrganizationIdGet$Params, context?: HttpContext): Observable<Array<OrganizationStatisticsModel>> {
    return this.integrationOrganizationStatisticsSystemIdentifierIdOrganizationIdGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<Array<OrganizationStatisticsModel>>): Array<OrganizationStatisticsModel> => r.body)
    );
  }

}
