import { Injectable, } from '@angular/core';
import { EventService } from '../events/event.service';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  loadingMap: Map<string, boolean> = new Map<string, boolean>();
  private expiryDate: Date | null = null;
  private loadingDisabled:boolean = false;


  constructor(
    private events: EventService,
  ) {

    setInterval(() => { this.checkClearLoading() }, 1000);


  }

  private checkClearLoading() {
    let now = new Date();
    if (this.expiryDate && now > this.expiryDate) {
      this.closeAllLoaders();
    }
  }

  public disableLoading(){
    this.loadingDisabled = true;
    this.closeAllLoaders();
  }

  public enableLoading(){
    this.loadingDisabled = false;
    this.closeAllLoaders();
  }



  setLoading(loading: boolean, url: string) {
    if (loading) {
      if(this.loadingDisabled)
        return;

      this.loadingMap.set(url, loading);

      // Set the expiry date to 30 seconds from now
      this.expiryDate = new Date();
      this.expiryDate.setTime(this.expiryDate.getTime() + 30 * 1000); // Add 30 seconds

    } else {
      if (this.loadingMap.has(url)) {
        this.loadingMap.delete(url);
      }
      if (this.loadingMap.size <= 0)
        this.expiryDate = null;
    }
    if (!loading) {
      setTimeout(() => {
        this.events.loadingEvent.emit(this.loadingMap.size);
      }, 500);//debounce the event so that we kill the flashing loaders
    } else {
      this.events.loadingEvent.emit(this.loadingMap.size);
    }
  }


  closeAllLoaders() {
    this.loadingMap.clear();
    this.events.loadingEvent.emit(this.loadingMap.size);
  }

  getLastUrlSegments(url: string, segmentCount: number): string {
    const segments = url.split('/').filter(segment => segment.length > 0);
    const lastSegments = segments.slice(-segmentCount);
    return lastSegments.join('/');
  }

  getLastUrlSegment(url: string) {
    if (!url) return url;
    if (!url.includes('/')) return url;
    try {
      var lastSegment = new URL(url).pathname.split('/').filter(Boolean).pop();
      if (!lastSegment) lastSegment = '';

      if (this.isUUID(lastSegment)) {
        var arr = new URL(url).pathname.split('/').filter(Boolean);
        if (arr.length > 1) return arr[arr.length - 2];
      }
      return lastSegment;
    } catch {
      return url;
    }
  }

  isUUID(uuid: string) {
    let s = "" + uuid;
    var matchArrayy = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    if (matchArrayy === null) {
      return false;
    }
    return true;
  }

  public removeParametersFromUrl(url: string): string {
    const paramRegex = /{[^{}]+}/g;
    return url.replace(paramRegex, '');
  }


}
