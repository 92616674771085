import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { lastValueFrom } from 'rxjs';
import { sharedConfig } from 'src/environments/shared-config';

@Injectable({
    providedIn: 'root'
})
export class TranslatorService {
    private defaultLanguage: string = environment.defaultLanguage;
    private currentLangauge: string = environment.defaultLanguage;
    public isRightToLeft: boolean = false;

    constructor(public translate: TranslateService, 
        @Inject(DOCUMENT) private document: Document) {
        if (!translate.getDefaultLang()) translate.setDefaultLang(this.defaultLanguage);

        this.useLanguage(this.currentLangauge);
    }

    async useLanguage(lang: string = '', fireEvents: boolean = true): Promise<void> {
        const lanToUse = lang || this.translate.getDefaultLang();

        const selectedLanguage = sharedConfig.availablelanguages.find(obj => obj.code === lanToUse);

        if (selectedLanguage) {
            this.currentLangauge = lanToUse;
            this.document.documentElement.lang = lanToUse;
            this.document.documentElement.dir = selectedLanguage.dir;
            const elem = this.document.getElementsByClassName('section-container')[0] as HTMLElement;
            if (elem) {
                elem.dir = selectedLanguage.dir;
            }
            this.isRightToLeft = selectedLanguage.dir === 'rtl';
        }

        if (fireEvents) {
            await lastValueFrom(this.translate.use(lanToUse));
        }
    }

    getAvailableLanguages() {
        return sharedConfig.availablelanguages;
    }

    getCurrentLanguage(): string {
        return this.currentLangauge;
    }

    getIsRTL(): boolean {
        return this.isRightToLeft;
    }

    async refreshRTL() {
       if (this.isRightToLeft) {
            const rememberLang = this.currentLangauge;
            await this.useLanguage('en', false);
            await this.useLanguage(rememberLang, false);
        }
    }

    translateText(key: string | string[], interpolateParams?: object): string {
        return this.translate.instant(key, interpolateParams);
    }
}
