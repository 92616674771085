/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { VitalScoreBaseService } from '../vital-score-base-service';
import { VitalScoreApiConfiguration } from '../vital-score-api-configuration';
import { VitalScoreStrictHttpResponse } from '../vital-score-strict-http-response';

import { AccessTokenModel } from '../models/access-token-model';
import { HealthScoreHistoryModel } from '../models/health-score-history-model';
import { HealthScoreResponseModel } from '../models/health-score-response-model';
import { profileAppLogMessagePost } from '../fn/profile/profile-app-log-message-post';
import { ProfileAppLogMessagePost$Params } from '../fn/profile/profile-app-log-message-post';
import { profileDelete } from '../fn/profile/profile-delete';
import { ProfileDelete$Params } from '../fn/profile/profile-delete';
import { profileGetProfileGet } from '../fn/profile/profile-get-profile-get';
import { ProfileGetProfileGet$Params } from '../fn/profile/profile-get-profile-get';
import { profileHealthScoreGet } from '../fn/profile/profile-health-score-get';
import { ProfileHealthScoreGet$Params } from '../fn/profile/profile-health-score-get';
import { profileHealthScoreHistoryPost } from '../fn/profile/profile-health-score-history-post';
import { ProfileHealthScoreHistoryPost$Params } from '../fn/profile/profile-health-score-history-post';
import { profileHealthScoreRefreshGet } from '../fn/profile/profile-health-score-refresh-get';
import { ProfileHealthScoreRefreshGet$Params } from '../fn/profile/profile-health-score-refresh-get';
import { profileLogPageAccessPost } from '../fn/profile/profile-log-page-access-post';
import { ProfileLogPageAccessPost$Params } from '../fn/profile/profile-log-page-access-post';
import { profileLogTimeSpentPost } from '../fn/profile/profile-log-time-spent-post';
import { ProfileLogTimeSpentPost$Params } from '../fn/profile/profile-log-time-spent-post';
import { ProfileModel } from '../models/profile-model';
import { profileOrganizationReportGet } from '../fn/profile/profile-organization-report-get';
import { ProfileOrganizationReportGet$Params } from '../fn/profile/profile-organization-report-get';
import { profileRegisterProfilePost } from '../fn/profile/profile-register-profile-post';
import { ProfileRegisterProfilePost$Params } from '../fn/profile/profile-register-profile-post';
import { profileSaveProfilePost } from '../fn/profile/profile-save-profile-post';
import { ProfileSaveProfilePost$Params } from '../fn/profile/profile-save-profile-post';

@Injectable({ providedIn: 'root' })
export class VitalScoreProfileService extends VitalScoreBaseService {
  constructor(config: VitalScoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `profileRegisterProfilePost()` */
  static readonly ProfileRegisterProfilePostPath = '/Profile/RegisterProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileRegisterProfilePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  profileRegisterProfilePost$Response(params?: ProfileRegisterProfilePost$Params, context?: HttpContext): Observable<VitalScoreStrictHttpResponse<AccessTokenModel>> {
    return profileRegisterProfilePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `profileRegisterProfilePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  profileRegisterProfilePost(params?: ProfileRegisterProfilePost$Params, context?: HttpContext): Observable<AccessTokenModel> {
    return this.profileRegisterProfilePost$Response(params, context).pipe(
      map((r: VitalScoreStrictHttpResponse<AccessTokenModel>): AccessTokenModel => r.body)
    );
  }

  /** Path part for operation `profileSaveProfilePost()` */
  static readonly ProfileSaveProfilePostPath = '/Profile/SaveProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileSaveProfilePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  profileSaveProfilePost$Response(params?: ProfileSaveProfilePost$Params, context?: HttpContext): Observable<VitalScoreStrictHttpResponse<ProfileModel>> {
    return profileSaveProfilePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `profileSaveProfilePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  profileSaveProfilePost(params?: ProfileSaveProfilePost$Params, context?: HttpContext): Observable<ProfileModel> {
    return this.profileSaveProfilePost$Response(params, context).pipe(
      map((r: VitalScoreStrictHttpResponse<ProfileModel>): ProfileModel => r.body)
    );
  }

  /** Path part for operation `profileGetProfileGet()` */
  static readonly ProfileGetProfileGetPath = '/Profile/GetProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileGetProfileGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileGetProfileGet$Response(params?: ProfileGetProfileGet$Params, context?: HttpContext): Observable<VitalScoreStrictHttpResponse<ProfileModel>> {
    return profileGetProfileGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `profileGetProfileGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileGetProfileGet(params?: ProfileGetProfileGet$Params, context?: HttpContext): Observable<ProfileModel> {
    return this.profileGetProfileGet$Response(params, context).pipe(
      map((r: VitalScoreStrictHttpResponse<ProfileModel>): ProfileModel => r.body)
    );
  }

  /** Path part for operation `profileHealthScoreGet()` */
  static readonly ProfileHealthScoreGetPath = '/Profile/HealthScore';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileHealthScoreGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileHealthScoreGet$Response(params?: ProfileHealthScoreGet$Params, context?: HttpContext): Observable<VitalScoreStrictHttpResponse<HealthScoreResponseModel>> {
    return profileHealthScoreGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `profileHealthScoreGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileHealthScoreGet(params?: ProfileHealthScoreGet$Params, context?: HttpContext): Observable<HealthScoreResponseModel> {
    return this.profileHealthScoreGet$Response(params, context).pipe(
      map((r: VitalScoreStrictHttpResponse<HealthScoreResponseModel>): HealthScoreResponseModel => r.body)
    );
  }

  /** Path part for operation `profileHealthScoreRefreshGet()` */
  static readonly ProfileHealthScoreRefreshGetPath = '/Profile/HealthScoreRefresh';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileHealthScoreRefreshGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileHealthScoreRefreshGet$Response(params?: ProfileHealthScoreRefreshGet$Params, context?: HttpContext): Observable<VitalScoreStrictHttpResponse<HealthScoreResponseModel>> {
    return profileHealthScoreRefreshGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `profileHealthScoreRefreshGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileHealthScoreRefreshGet(params?: ProfileHealthScoreRefreshGet$Params, context?: HttpContext): Observable<HealthScoreResponseModel> {
    return this.profileHealthScoreRefreshGet$Response(params, context).pipe(
      map((r: VitalScoreStrictHttpResponse<HealthScoreResponseModel>): HealthScoreResponseModel => r.body)
    );
  }

  /** Path part for operation `profileHealthScoreHistoryPost()` */
  static readonly ProfileHealthScoreHistoryPostPath = '/Profile/HealthScoreHistory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileHealthScoreHistoryPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  profileHealthScoreHistoryPost$Response(params?: ProfileHealthScoreHistoryPost$Params, context?: HttpContext): Observable<VitalScoreStrictHttpResponse<Array<HealthScoreHistoryModel>>> {
    return profileHealthScoreHistoryPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `profileHealthScoreHistoryPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  profileHealthScoreHistoryPost(params?: ProfileHealthScoreHistoryPost$Params, context?: HttpContext): Observable<Array<HealthScoreHistoryModel>> {
    return this.profileHealthScoreHistoryPost$Response(params, context).pipe(
      map((r: VitalScoreStrictHttpResponse<Array<HealthScoreHistoryModel>>): Array<HealthScoreHistoryModel> => r.body)
    );
  }

  /** Path part for operation `profileDelete()` */
  static readonly ProfileDeletePath = '/Profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileDelete$Response(params?: ProfileDelete$Params, context?: HttpContext): Observable<VitalScoreStrictHttpResponse<void>> {
    return profileDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `profileDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileDelete(params?: ProfileDelete$Params, context?: HttpContext): Observable<void> {
    return this.profileDelete$Response(params, context).pipe(
      map((r: VitalScoreStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `profileLogPageAccessPost()` */
  static readonly ProfileLogPageAccessPostPath = '/Profile/LogPageAccess';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileLogPageAccessPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  profileLogPageAccessPost$Response(params?: ProfileLogPageAccessPost$Params, context?: HttpContext): Observable<VitalScoreStrictHttpResponse<boolean>> {
    return profileLogPageAccessPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `profileLogPageAccessPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  profileLogPageAccessPost(params?: ProfileLogPageAccessPost$Params, context?: HttpContext): Observable<boolean> {
    return this.profileLogPageAccessPost$Response(params, context).pipe(
      map((r: VitalScoreStrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `profileLogTimeSpentPost()` */
  static readonly ProfileLogTimeSpentPostPath = '/Profile/LogTimeSpent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileLogTimeSpentPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  profileLogTimeSpentPost$Response(params?: ProfileLogTimeSpentPost$Params, context?: HttpContext): Observable<VitalScoreStrictHttpResponse<boolean>> {
    return profileLogTimeSpentPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `profileLogTimeSpentPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  profileLogTimeSpentPost(params?: ProfileLogTimeSpentPost$Params, context?: HttpContext): Observable<boolean> {
    return this.profileLogTimeSpentPost$Response(params, context).pipe(
      map((r: VitalScoreStrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `profileAppLogMessagePost()` */
  static readonly ProfileAppLogMessagePostPath = '/Profile/AppLogMessage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileAppLogMessagePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  profileAppLogMessagePost$Response(params?: ProfileAppLogMessagePost$Params, context?: HttpContext): Observable<VitalScoreStrictHttpResponse<void>> {
    return profileAppLogMessagePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `profileAppLogMessagePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  profileAppLogMessagePost(params?: ProfileAppLogMessagePost$Params, context?: HttpContext): Observable<void> {
    return this.profileAppLogMessagePost$Response(params, context).pipe(
      map((r: VitalScoreStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `profileOrganizationReportGet()` */
  static readonly ProfileOrganizationReportGetPath = '/Profile/OrganizationReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileOrganizationReportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileOrganizationReportGet$Response(params?: ProfileOrganizationReportGet$Params, context?: HttpContext): Observable<VitalScoreStrictHttpResponse<void>> {
    return profileOrganizationReportGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `profileOrganizationReportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileOrganizationReportGet(params?: ProfileOrganizationReportGet$Params, context?: HttpContext): Observable<void> {
    return this.profileOrganizationReportGet$Response(params, context).pipe(
      map((r: VitalScoreStrictHttpResponse<void>): void => r.body)
    );
  }

}
