/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MPIBaseService } from '../mpi-base-service';
import { MPIApiConfiguration } from '../mpi-api-configuration';
import { MPIStrictHttpResponse } from '../mpi-strict-http-response';

import { syncSyncPersonsPost } from '../fn/sync/sync-sync-persons-post';
import { SyncSyncPersonsPost$Params } from '../fn/sync/sync-sync-persons-post';

@Injectable({ providedIn: 'root' })
export class MPISyncService extends MPIBaseService {
  constructor(config: MPIApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `syncSyncPersonsPost()` */
  static readonly SyncSyncPersonsPostPath = '/Sync/SyncPersons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `syncSyncPersonsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  syncSyncPersonsPost$Response(params?: SyncSyncPersonsPost$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<number>> {
    return syncSyncPersonsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `syncSyncPersonsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  syncSyncPersonsPost(params?: SyncSyncPersonsPost$Params, context?: HttpContext): Observable<number> {
    return this.syncSyncPersonsPost$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<number>): number => r.body)
    );
  }

}
