/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { PointsStrictHttpResponse } from '../../points-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PointsHistoryRequest } from '../../models/points-history-request';
import { PointsHistoryResponse } from '../../models/points-history-response';

export interface ConsumerHistoryPost$Params {
      body?: PointsHistoryRequest
}

export function consumerHistoryPost(http: HttpClient, rootUrl: string, params?: ConsumerHistoryPost$Params, context?: HttpContext): Observable<PointsStrictHttpResponse<PointsHistoryResponse>> {
  const rb = new RequestBuilder(rootUrl, consumerHistoryPost.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as PointsStrictHttpResponse<PointsHistoryResponse>;
    })
  );
}

consumerHistoryPost.PATH = '/Consumer/History';
