/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { NotifyStrictHttpResponse } from '../../notify-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { NotificationHistoryItem } from '../../models/notification-history-item';

export interface ConsumerNotificationHistoryGet$Params {
  pageNumber?: number;
  pageSize?: number;
}

export function consumerNotificationHistoryGet(http: HttpClient, rootUrl: string, params?: ConsumerNotificationHistoryGet$Params, context?: HttpContext): Observable<NotifyStrictHttpResponse<Array<NotificationHistoryItem>>> {
  const rb = new RequestBuilder(rootUrl, consumerNotificationHistoryGet.PATH, 'get');
  if (params) {
    rb.query('pageNumber', params.pageNumber, {});
    rb.query('pageSize', params.pageSize, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as NotifyStrictHttpResponse<Array<NotificationHistoryItem>>;
    })
  );
}

consumerNotificationHistoryGet.PATH = '/Consumer/NotificationHistory';
