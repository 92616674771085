/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NotifyBaseService } from '../notify-base-service';
import { NotifyApiConfiguration } from '../notify-api-configuration';
import { NotifyStrictHttpResponse } from '../notify-strict-http-response';

import { pushNotifyProviderEventPost } from '../fn/push-notify-provider/push-notify-provider-event-post';
import { PushNotifyProviderEventPost$Params } from '../fn/push-notify-provider/push-notify-provider-event-post';
import { pushNotifyProviderUserOptOutPost } from '../fn/push-notify-provider/push-notify-provider-user-opt-out-post';
import { PushNotifyProviderUserOptOutPost$Params } from '../fn/push-notify-provider/push-notify-provider-user-opt-out-post';
import { pushNotifyProviderUserStateChangedPost } from '../fn/push-notify-provider/push-notify-provider-user-state-changed-post';
import { PushNotifyProviderUserStateChangedPost$Params } from '../fn/push-notify-provider/push-notify-provider-user-state-changed-post';

@Injectable({ providedIn: 'root' })
export class NotifyPushNotifyProviderService extends NotifyBaseService {
  constructor(config: NotifyApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `pushNotifyProviderUserStateChangedPost()` */
  static readonly PushNotifyProviderUserStateChangedPostPath = '/PushNotifyProvider/UserStateChanged';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pushNotifyProviderUserStateChangedPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  pushNotifyProviderUserStateChangedPost$Response(params?: PushNotifyProviderUserStateChangedPost$Params, context?: HttpContext): Observable<NotifyStrictHttpResponse<void>> {
    return pushNotifyProviderUserStateChangedPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pushNotifyProviderUserStateChangedPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  pushNotifyProviderUserStateChangedPost(params?: PushNotifyProviderUserStateChangedPost$Params, context?: HttpContext): Observable<void> {
    return this.pushNotifyProviderUserStateChangedPost$Response(params, context).pipe(
      map((r: NotifyStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `pushNotifyProviderEventPost()` */
  static readonly PushNotifyProviderEventPostPath = '/PushNotifyProvider/Event';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pushNotifyProviderEventPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  pushNotifyProviderEventPost$Response(params?: PushNotifyProviderEventPost$Params, context?: HttpContext): Observable<NotifyStrictHttpResponse<void>> {
    return pushNotifyProviderEventPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pushNotifyProviderEventPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  pushNotifyProviderEventPost(params?: PushNotifyProviderEventPost$Params, context?: HttpContext): Observable<void> {
    return this.pushNotifyProviderEventPost$Response(params, context).pipe(
      map((r: NotifyStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `pushNotifyProviderUserOptOutPost()` */
  static readonly PushNotifyProviderUserOptOutPostPath = '/PushNotifyProvider/UserOptOut';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pushNotifyProviderUserOptOutPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  pushNotifyProviderUserOptOutPost$Response(params?: PushNotifyProviderUserOptOutPost$Params, context?: HttpContext): Observable<NotifyStrictHttpResponse<void>> {
    return pushNotifyProviderUserOptOutPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pushNotifyProviderUserOptOutPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  pushNotifyProviderUserOptOutPost(params?: PushNotifyProviderUserOptOutPost$Params, context?: HttpContext): Observable<void> {
    return this.pushNotifyProviderUserOptOutPost$Response(params, context).pipe(
      map((r: NotifyStrictHttpResponse<void>): void => r.body)
    );
  }

}
