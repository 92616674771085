export const environment = {
	deepLinkURLBase:'https://vivascore.alula.health',
	appEnvironmentBaseUrl:'https://api.alulahealth.com/health/environment',
	
	dislaimerUrl:'https://www.alulatechnologies.com/VivaScore_Disclaimer',
	
	welcomeBackground: '/assets/v2/welcome/background.png',
	oneSignalAppId: 'fc358462-7175-4b89-9bf5-13224240e909',
	environmentName: 'production',
	production: true,	
	defaultLanguage: 'en',

	countryCode: undefined,
	organizationId: undefined,
	scanFriendAllowed: true
};
