/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthenticationStrictHttpResponse } from '../../authentication-strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface LocalAccountDeleteAccountDelete$Params {
}

export function localAccountDeleteAccountDelete(http: HttpClient, rootUrl: string, params?: LocalAccountDeleteAccountDelete$Params, context?: HttpContext): Observable<AuthenticationStrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, localAccountDeleteAccountDelete.PATH, 'delete');
  if (params) {
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as AuthenticationStrictHttpResponse<void>;
    })
  );
}

localAccountDeleteAccountDelete.PATH = '/LocalAccount/DeleteAccount';
