import {
  Component,
  NgZone,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ServicesStartupService } from './services/services-startup/services-startup.service';
import { Capacitor } from '@capacitor/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { NavController } from '@ionic/angular';
import { EventService } from './services/events/event.service';
import { SystemLogService } from './services/systemlog-service/systemlog.service';
import { environment } from 'src/environments/environment';
import { register } from 'swiper/element/bundle';
import { StorageService } from './services/storage/storage.service';
import { SecureStorageKey } from './services/storage/models/secure-storage-key.enum';
import { UserInfoService } from './services/auth-service/user-info-service';
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('viewContainerRef', { read: ViewContainerRef, static: true }) vcr!: ViewContainerRef;

  // public showMenu = true;
  busyLoading = false;

  constructor(
    private zone: NgZone,
    public servicesStartupService: ServicesStartupService,
    public navCtrl: NavController,
    private events: EventService,
    private _systemLogService: SystemLogService,
    private renderer: Renderer2,
    private _storageService: StorageService,
    private _events: EventService,
    private _userService: UserInfoService
  ) {
    this.events.loadingEvent.subscribe(async (loading: number) => {
      this.busyLoading = loading > 0;
    });
    this.initializeApp();
  }

  initializeApp() {
    App.removeAllListeners();
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      setTimeout(async () => {
        const slug = event.url.split(".app").pop();
        if (slug) {
          try {
            var path = slug.toLowerCase();
            if (path.includes(environment.deepLinkURLBase!)) {
              path = path.replace(environment.deepLinkURLBase!, '');
              if (path.startsWith('/'))
                path = path.replace(/^./, "");
            }
            //set a one time deeplink url
            //this.events.appDeeplinkEvent.emit(path);
            await this.navigateToDeepLink(path);

          } catch (e) {
            console.error(e);
          }
        }
      }, 100);
    });


    App.addListener('resume', () => {
      setTimeout(async () => {
        try {
          await this.servicesStartupService.resumeApp();
          this.events.appResumed.emit();
          const usr = await this._userService.getUserInfo(); // if a user is logged in, check time paused

          if (usr && usr.timeAppPaused) {
            const currentTime = new Date();
            const timeAppPaused = new Date(usr.timeAppPaused);
            const timeDifference = (currentTime.getTime() - timeAppPaused.getTime()) / 1000 / 60; // convert to minutes

            if (timeDifference > 1) {
              await this.navCtrl.navigateRoot("/");
            }
          }
        } catch (error) {
          console.error('Error on app resume:', error);
        }
      }, 1000);
    });

    App.addListener('pause', () => {
      setTimeout(async () => {
        try {
          var usr = await this._userService.getUserInfo();//if a user is logged in go back home
          if (usr) {
            usr.timeAppPaused = new Date();
            await this._userService.setUserInfo(usr);
          }
          this.events.appPaused.emit();
        } catch (error) {
          console.error('Error on app pause:', error);
        }
      }, 10);
    });


  }


  async ngOnInit(): Promise<void> {

    if (!Capacitor.isNativePlatform()) {
      this.renderer.addClass(document.body, 'web-platform');
    }
    await this.servicesStartupService.initServices();
  }

  async navigateToDeepLink(path: string): Promise<void> {
    var linkPath = {
      path: path
    }
    var pathJSON = JSON.stringify(linkPath);
    await this._storageService.set(SecureStorageKey.DeeplinkPath, pathJSON);
    this._events.appDeeplinkEvent.emit(path);
  }

}
