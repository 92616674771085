/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { WearablesBaseService } from '../wearables-base-service';
import { WearablesApiConfiguration } from '../wearables-api-configuration';
import { WearablesStrictHttpResponse } from '../wearables-strict-http-response';

import { monitorProbeGet } from '../fn/monitor/monitor-probe-get';
import { MonitorProbeGet$Params } from '../fn/monitor/monitor-probe-get';
import { MonitorProbeResultModel } from '../models/monitor-probe-result-model';

@Injectable({ providedIn: 'root' })
export class WearablesMonitorService extends WearablesBaseService {
  constructor(config: WearablesApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `monitorProbeGet()` */
  static readonly MonitorProbeGetPath = '/Monitor/Probe';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `monitorProbeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  monitorProbeGet$Response(params?: MonitorProbeGet$Params, context?: HttpContext): Observable<WearablesStrictHttpResponse<MonitorProbeResultModel>> {
    return monitorProbeGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `monitorProbeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  monitorProbeGet(params?: MonitorProbeGet$Params, context?: HttpContext): Observable<MonitorProbeResultModel> {
    return this.monitorProbeGet$Response(params, context).pipe(
      map((r: WearablesStrictHttpResponse<MonitorProbeResultModel>): MonitorProbeResultModel => r.body)
    );
  }

}
