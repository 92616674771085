/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { VitalScoreBaseService } from '../vital-score-base-service';
import { VitalScoreApiConfiguration } from '../vital-score-api-configuration';
import { VitalScoreStrictHttpResponse } from '../vital-score-strict-http-response';

import { dashboardHealthScoreOrganizationIdPost } from '../fn/dashboard/dashboard-health-score-organization-id-post';
import { DashboardHealthScoreOrganizationIdPost$Params } from '../fn/dashboard/dashboard-health-score-organization-id-post';
import { DashboardItemModel } from '../models/dashboard-item-model';
import { dashboardOrganizationListGet } from '../fn/dashboard/dashboard-organization-list-get';
import { DashboardOrganizationListGet$Params } from '../fn/dashboard/dashboard-organization-list-get';
import { dashboardTimeInAppOrganizationIdPost } from '../fn/dashboard/dashboard-time-in-app-organization-id-post';
import { DashboardTimeInAppOrganizationIdPost$Params } from '../fn/dashboard/dashboard-time-in-app-organization-id-post';
import { dashboardUserProfileOrganizationIdPost } from '../fn/dashboard/dashboard-user-profile-organization-id-post';
import { DashboardUserProfileOrganizationIdPost$Params } from '../fn/dashboard/dashboard-user-profile-organization-id-post';
import { OrganizationItemModel } from '../models/organization-item-model';

@Injectable({ providedIn: 'root' })
export class VitalScoreDashboardService extends VitalScoreBaseService {
  constructor(config: VitalScoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `dashboardOrganizationListGet()` */
  static readonly DashboardOrganizationListGetPath = '/Dashboard/OrganizationList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardOrganizationListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardOrganizationListGet$Response(params?: DashboardOrganizationListGet$Params, context?: HttpContext): Observable<VitalScoreStrictHttpResponse<Array<OrganizationItemModel>>> {
    return dashboardOrganizationListGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dashboardOrganizationListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardOrganizationListGet(params?: DashboardOrganizationListGet$Params, context?: HttpContext): Observable<Array<OrganizationItemModel>> {
    return this.dashboardOrganizationListGet$Response(params, context).pipe(
      map((r: VitalScoreStrictHttpResponse<Array<OrganizationItemModel>>): Array<OrganizationItemModel> => r.body)
    );
  }

  /** Path part for operation `dashboardUserProfileOrganizationIdPost()` */
  static readonly DashboardUserProfileOrganizationIdPostPath = '/Dashboard/UserProfile/{organizationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardUserProfileOrganizationIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dashboardUserProfileOrganizationIdPost$Response(params: DashboardUserProfileOrganizationIdPost$Params, context?: HttpContext): Observable<VitalScoreStrictHttpResponse<Array<DashboardItemModel>>> {
    return dashboardUserProfileOrganizationIdPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dashboardUserProfileOrganizationIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dashboardUserProfileOrganizationIdPost(params: DashboardUserProfileOrganizationIdPost$Params, context?: HttpContext): Observable<Array<DashboardItemModel>> {
    return this.dashboardUserProfileOrganizationIdPost$Response(params, context).pipe(
      map((r: VitalScoreStrictHttpResponse<Array<DashboardItemModel>>): Array<DashboardItemModel> => r.body)
    );
  }

  /** Path part for operation `dashboardHealthScoreOrganizationIdPost()` */
  static readonly DashboardHealthScoreOrganizationIdPostPath = '/Dashboard/HealthScore/{organizationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardHealthScoreOrganizationIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dashboardHealthScoreOrganizationIdPost$Response(params: DashboardHealthScoreOrganizationIdPost$Params, context?: HttpContext): Observable<VitalScoreStrictHttpResponse<Array<DashboardItemModel>>> {
    return dashboardHealthScoreOrganizationIdPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dashboardHealthScoreOrganizationIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dashboardHealthScoreOrganizationIdPost(params: DashboardHealthScoreOrganizationIdPost$Params, context?: HttpContext): Observable<Array<DashboardItemModel>> {
    return this.dashboardHealthScoreOrganizationIdPost$Response(params, context).pipe(
      map((r: VitalScoreStrictHttpResponse<Array<DashboardItemModel>>): Array<DashboardItemModel> => r.body)
    );
  }

  /** Path part for operation `dashboardTimeInAppOrganizationIdPost()` */
  static readonly DashboardTimeInAppOrganizationIdPostPath = '/Dashboard/TimeInApp/{organizationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardTimeInAppOrganizationIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dashboardTimeInAppOrganizationIdPost$Response(params: DashboardTimeInAppOrganizationIdPost$Params, context?: HttpContext): Observable<VitalScoreStrictHttpResponse<Array<DashboardItemModel>>> {
    return dashboardTimeInAppOrganizationIdPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dashboardTimeInAppOrganizationIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dashboardTimeInAppOrganizationIdPost(params: DashboardTimeInAppOrganizationIdPost$Params, context?: HttpContext): Observable<Array<DashboardItemModel>> {
    return this.dashboardTimeInAppOrganizationIdPost$Response(params, context).pipe(
      map((r: VitalScoreStrictHttpResponse<Array<DashboardItemModel>>): Array<DashboardItemModel> => r.body)
    );
  }

}
