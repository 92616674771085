/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { NotifyStrictHttpResponse } from '../../notify-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { UserStateChanged } from '../../models/user-state-changed';

export interface PushNotifyProviderUserStateChangedPost$Params {
      body?: UserStateChanged
}

export function pushNotifyProviderUserStateChangedPost(http: HttpClient, rootUrl: string, params?: PushNotifyProviderUserStateChangedPost$Params, context?: HttpContext): Observable<NotifyStrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, pushNotifyProviderUserStateChangedPost.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as NotifyStrictHttpResponse<void>;
    })
  );
}

pushNotifyProviderUserStateChangedPost.PATH = '/PushNotifyProvider/UserStateChanged';
