/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppEnvironmentBaseService } from '../app-environment-base-service';
import { AppEnvironmentApiConfiguration } from '../app-environment-api-configuration';
import { AppEnvironmentStrictHttpResponse } from '../app-environment-strict-http-response';

import { appEnvironmentCountriesForUserDeviceTypeAppIdProductionGet } from '../fn/app-environment/app-environment-countries-for-user-device-type-app-id-production-get';
import { AppEnvironmentCountriesForUserDeviceTypeAppIdProductionGet$Params } from '../fn/app-environment/app-environment-countries-for-user-device-type-app-id-production-get';
import { appEnvironmentCountriesGet } from '../fn/app-environment/app-environment-countries-get';
import { AppEnvironmentCountriesGet$Params } from '../fn/app-environment/app-environment-countries-get';
import { appEnvironmentEnvironmentSetupEnvironmentNameCountryCodeDeviceTypeNameAppIdVersionBuildProductionGet } from '../fn/app-environment/app-environment-environment-setup-environment-name-country-code-device-type-name-app-id-version-build-production-get';
import { AppEnvironmentEnvironmentSetupEnvironmentNameCountryCodeDeviceTypeNameAppIdVersionBuildProductionGet$Params } from '../fn/app-environment/app-environment-environment-setup-environment-name-country-code-device-type-name-app-id-version-build-production-get';
import { appEnvironmentGetVivaScoreAsyncPost } from '../fn/app-environment/app-environment-get-viva-score-async-post';
import { AppEnvironmentGetVivaScoreAsyncPost$Params } from '../fn/app-environment/app-environment-get-viva-score-async-post';
import { appEnvironmentSettingsPost } from '../fn/app-environment/app-environment-settings-post';
import { AppEnvironmentSettingsPost$Params } from '../fn/app-environment/app-environment-settings-post';
import { appEnvironmentUserEnvironmentCountAsyncGet } from '../fn/app-environment/app-environment-user-environment-count-async-get';
import { AppEnvironmentUserEnvironmentCountAsyncGet$Params } from '../fn/app-environment/app-environment-user-environment-count-async-get';
import { appEnvironmentVivaScorePingPost } from '../fn/app-environment/app-environment-viva-score-ping-post';
import { AppEnvironmentVivaScorePingPost$Params } from '../fn/app-environment/app-environment-viva-score-ping-post';
import { CountryItemModel } from '../models/country-item-model';
import { IAppEnvironment } from '../models/i-app-environment';

@Injectable({ providedIn: 'root' })
export class AppEnvironmentAppEnvironmentService extends AppEnvironmentBaseService {
  constructor(config: AppEnvironmentApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `appEnvironmentSettingsPost()` */
  static readonly AppEnvironmentSettingsPostPath = '/AppEnvironment/Settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appEnvironmentSettingsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  appEnvironmentSettingsPost$Response(params?: AppEnvironmentSettingsPost$Params, context?: HttpContext): Observable<AppEnvironmentStrictHttpResponse<IAppEnvironment>> {
    return appEnvironmentSettingsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `appEnvironmentSettingsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  appEnvironmentSettingsPost(params?: AppEnvironmentSettingsPost$Params, context?: HttpContext): Observable<IAppEnvironment> {
    return this.appEnvironmentSettingsPost$Response(params, context).pipe(
      map((r: AppEnvironmentStrictHttpResponse<IAppEnvironment>): IAppEnvironment => r.body)
    );
  }

  /** Path part for operation `appEnvironmentCountriesGet()` */
  static readonly AppEnvironmentCountriesGetPath = '/AppEnvironment/Countries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appEnvironmentCountriesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  appEnvironmentCountriesGet$Response(params?: AppEnvironmentCountriesGet$Params, context?: HttpContext): Observable<AppEnvironmentStrictHttpResponse<Array<CountryItemModel>>> {
    return appEnvironmentCountriesGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `appEnvironmentCountriesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appEnvironmentCountriesGet(params?: AppEnvironmentCountriesGet$Params, context?: HttpContext): Observable<Array<CountryItemModel>> {
    return this.appEnvironmentCountriesGet$Response(params, context).pipe(
      map((r: AppEnvironmentStrictHttpResponse<Array<CountryItemModel>>): Array<CountryItemModel> => r.body)
    );
  }

  /** Path part for operation `appEnvironmentEnvironmentSetupEnvironmentNameCountryCodeDeviceTypeNameAppIdVersionBuildProductionGet()` */
  static readonly AppEnvironmentEnvironmentSetupEnvironmentNameCountryCodeDeviceTypeNameAppIdVersionBuildProductionGetPath = '/AppEnvironment/EnvironmentSetup/{environmentName}/{countryCode}/{deviceType}/{name}/{appId}/{version}/{build}/{production}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appEnvironmentEnvironmentSetupEnvironmentNameCountryCodeDeviceTypeNameAppIdVersionBuildProductionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  appEnvironmentEnvironmentSetupEnvironmentNameCountryCodeDeviceTypeNameAppIdVersionBuildProductionGet$Response(params: AppEnvironmentEnvironmentSetupEnvironmentNameCountryCodeDeviceTypeNameAppIdVersionBuildProductionGet$Params, context?: HttpContext): Observable<AppEnvironmentStrictHttpResponse<IAppEnvironment>> {
    return appEnvironmentEnvironmentSetupEnvironmentNameCountryCodeDeviceTypeNameAppIdVersionBuildProductionGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `appEnvironmentEnvironmentSetupEnvironmentNameCountryCodeDeviceTypeNameAppIdVersionBuildProductionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appEnvironmentEnvironmentSetupEnvironmentNameCountryCodeDeviceTypeNameAppIdVersionBuildProductionGet(params: AppEnvironmentEnvironmentSetupEnvironmentNameCountryCodeDeviceTypeNameAppIdVersionBuildProductionGet$Params, context?: HttpContext): Observable<IAppEnvironment> {
    return this.appEnvironmentEnvironmentSetupEnvironmentNameCountryCodeDeviceTypeNameAppIdVersionBuildProductionGet$Response(params, context).pipe(
      map((r: AppEnvironmentStrictHttpResponse<IAppEnvironment>): IAppEnvironment => r.body)
    );
  }

  /** Path part for operation `appEnvironmentCountriesForUserDeviceTypeAppIdProductionGet()` */
  static readonly AppEnvironmentCountriesForUserDeviceTypeAppIdProductionGetPath = '/AppEnvironment/CountriesForUser/{deviceType}/{appId}/{production}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appEnvironmentCountriesForUserDeviceTypeAppIdProductionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  appEnvironmentCountriesForUserDeviceTypeAppIdProductionGet$Response(params: AppEnvironmentCountriesForUserDeviceTypeAppIdProductionGet$Params, context?: HttpContext): Observable<AppEnvironmentStrictHttpResponse<Array<CountryItemModel>>> {
    return appEnvironmentCountriesForUserDeviceTypeAppIdProductionGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `appEnvironmentCountriesForUserDeviceTypeAppIdProductionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appEnvironmentCountriesForUserDeviceTypeAppIdProductionGet(params: AppEnvironmentCountriesForUserDeviceTypeAppIdProductionGet$Params, context?: HttpContext): Observable<Array<CountryItemModel>> {
    return this.appEnvironmentCountriesForUserDeviceTypeAppIdProductionGet$Response(params, context).pipe(
      map((r: AppEnvironmentStrictHttpResponse<Array<CountryItemModel>>): Array<CountryItemModel> => r.body)
    );
  }

  /** Path part for operation `appEnvironmentUserEnvironmentCountAsyncGet()` */
  static readonly AppEnvironmentUserEnvironmentCountAsyncGetPath = '/AppEnvironment/UserEnvironmentCountAsync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appEnvironmentUserEnvironmentCountAsyncGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  appEnvironmentUserEnvironmentCountAsyncGet$Response(params?: AppEnvironmentUserEnvironmentCountAsyncGet$Params, context?: HttpContext): Observable<AppEnvironmentStrictHttpResponse<number>> {
    return appEnvironmentUserEnvironmentCountAsyncGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `appEnvironmentUserEnvironmentCountAsyncGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appEnvironmentUserEnvironmentCountAsyncGet(params?: AppEnvironmentUserEnvironmentCountAsyncGet$Params, context?: HttpContext): Observable<number> {
    return this.appEnvironmentUserEnvironmentCountAsyncGet$Response(params, context).pipe(
      map((r: AppEnvironmentStrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `appEnvironmentGetVivaScoreAsyncPost()` */
  static readonly AppEnvironmentGetVivaScoreAsyncPostPath = '/AppEnvironment/GetVivaScoreAsync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appEnvironmentGetVivaScoreAsyncPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  appEnvironmentGetVivaScoreAsyncPost$Response(params?: AppEnvironmentGetVivaScoreAsyncPost$Params, context?: HttpContext): Observable<AppEnvironmentStrictHttpResponse<string>> {
    return appEnvironmentGetVivaScoreAsyncPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `appEnvironmentGetVivaScoreAsyncPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  appEnvironmentGetVivaScoreAsyncPost(params?: AppEnvironmentGetVivaScoreAsyncPost$Params, context?: HttpContext): Observable<string> {
    return this.appEnvironmentGetVivaScoreAsyncPost$Response(params, context).pipe(
      map((r: AppEnvironmentStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `appEnvironmentVivaScorePingPost()` */
  static readonly AppEnvironmentVivaScorePingPostPath = '/AppEnvironment/VivaScorePing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appEnvironmentVivaScorePingPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  appEnvironmentVivaScorePingPost$Response(params?: AppEnvironmentVivaScorePingPost$Params, context?: HttpContext): Observable<AppEnvironmentStrictHttpResponse<string>> {
    return appEnvironmentVivaScorePingPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `appEnvironmentVivaScorePingPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  appEnvironmentVivaScorePingPost(params?: AppEnvironmentVivaScorePingPost$Params, context?: HttpContext): Observable<string> {
    return this.appEnvironmentVivaScorePingPost$Response(params, context).pipe(
      map((r: AppEnvironmentStrictHttpResponse<string>): string => r.body)
    );
  }

}
