<div class="modal-container">

    <svg class="close-icon"  (click)="dismiss()" width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.56" cx="26.4346" cy="25.9408" r="25.7573" fill="#D9D9D9" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M16.7645 16.2707C17.2545 15.7807 18.0489 15.7807 18.5389 16.2707L26.4346 24.1664L34.3303 16.2707C34.8203 15.7807 35.6147 15.7807 36.1047 16.2707C36.5947 16.7607 36.5947 17.5551 36.1047 18.0451L28.209 25.9408L36.1047 33.8365C36.5947 34.3265 36.5947 35.121 36.1047 35.6109C35.6147 36.1009 34.8203 36.1009 34.3303 35.6109L26.4346 27.7152L18.5389 35.6109C18.0489 36.1009 17.2545 36.1009 16.7645 35.6109C16.2745 35.121 16.2745 34.3265 16.7645 33.8365L24.6602 25.9408L16.7645 18.0451C16.2745 17.5551 16.2745 16.7607 16.7645 16.2707Z"
            fill="var(--ion-color-primary)"/>
    </svg>

    <svg class="info-icon"  width="93" height="93" viewBox="0 0 93 93" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M46.6919 0.903198C37.5917 0.903198 28.6958 3.60174 21.1292 8.65757C13.5626 13.7134 7.66515 20.8994 4.18263 29.307C0.700112 37.7145 -0.211073 46.966 1.5643 55.8913C3.33967 64.8167 7.72186 73.0153 14.1567 79.4501C20.5916 85.885 28.7901 90.2672 37.7155 92.0425C46.6409 93.8179 55.8923 92.9067 64.2998 89.4242C72.7074 85.9417 79.8934 80.0442 84.9492 72.4776C90.0051 64.9111 92.7036 56.0152 92.7036 46.9149C92.7036 40.8726 91.5135 34.8894 89.2012 29.307C86.8889 23.7246 83.4997 18.6523 79.2271 14.3797C74.9545 10.1071 69.8822 6.71794 64.2998 4.40563C58.7174 2.09333 52.7343 0.903198 46.6919 0.903198ZM46.6919 83.7243C39.4117 83.7243 32.295 81.5654 26.2417 77.5208C20.1884 73.4761 15.4705 67.7273 12.6845 61.0012C9.89847 54.2752 9.16952 46.8741 10.5898 39.7338C12.0101 32.5934 15.5159 26.0346 20.6638 20.8868C25.8116 15.7389 32.3704 12.2331 39.5108 10.8128C46.6511 9.39253 54.0522 10.1215 60.7782 12.9075C67.5043 15.6935 73.2531 20.4114 77.2978 26.4647C81.3424 32.518 83.5013 39.6347 83.5013 46.9149C83.5013 56.6774 79.6232 66.04 72.7201 72.9431C65.817 79.8462 56.4544 83.7243 46.6919 83.7243Z" fill="var(--ion-color-secondary)"/>
        <path d="M46.692 37.7126C45.4717 37.7126 44.3014 38.1974 43.4385 39.0602C42.5756 39.9231 42.0908 41.0935 42.0908 42.3138V65.3196C42.0908 66.5399 42.5756 67.7102 43.4385 68.5731C44.3014 69.436 45.4717 69.9208 46.692 69.9208C47.9123 69.9208 49.0826 69.436 49.9455 68.5731C50.8084 67.7102 51.2932 66.5399 51.2932 65.3196V42.3138C51.2932 41.0935 50.8084 39.9231 49.9455 39.0602C49.0826 38.1974 47.9123 37.7126 46.692 37.7126Z" fill="var(--ion-color-secondary)"/>
        <path d="M43.4252 25.2435C42.5844 26.1252 42.1078 27.2921 42.0909 28.5103C42.0874 29.1159 42.2035 29.7161 42.4324 30.2767C42.6614 30.8373 42.9988 31.3472 43.4252 31.7771C43.8725 32.1836 44.386 32.5104 44.9436 32.7434C45.4944 32.9868 46.0899 33.1126 46.6921 33.1126C47.2942 33.1126 47.8898 32.9868 48.4405 32.7434C48.9981 32.5104 49.5117 32.1836 49.9589 31.7771C50.3639 31.3288 50.6905 30.8155 50.9251 30.2588C51.1827 29.7127 51.3088 29.1139 51.2932 28.5103C51.2986 27.5964 51.0316 26.7016 50.5264 25.94C50.0212 25.1785 49.3006 24.5846 48.4565 24.2341C47.6125 23.8837 46.6832 23.7925 45.7871 23.9723C44.891 24.152 44.0688 24.5946 43.4252 25.2435Z" fill="var(--ion-color-secondary)"/>
        </svg>

        
    <span class="message" [innerHTML]="message"></span>
</div>