/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { VitalScoreBaseService } from '../vital-score-base-service';
import { VitalScoreApiConfiguration } from '../vital-score-api-configuration';
import { VitalScoreStrictHttpResponse } from '../vital-score-strict-http-response';

import { reportUserProfileListGet } from '../fn/report/report-user-profile-list-get';
import { ReportUserProfileListGet$Params } from '../fn/report/report-user-profile-list-get';
import { UserProfileDeviceReport } from '../models/user-profile-device-report';

@Injectable({ providedIn: 'root' })
export class VitalScoreReportService extends VitalScoreBaseService {
  constructor(config: VitalScoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `reportUserProfileListGet()` */
  static readonly ReportUserProfileListGetPath = '/Report/UserProfileList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportUserProfileListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportUserProfileListGet$Response(params?: ReportUserProfileListGet$Params, context?: HttpContext): Observable<VitalScoreStrictHttpResponse<Array<UserProfileDeviceReport>>> {
    return reportUserProfileListGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportUserProfileListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportUserProfileListGet(params?: ReportUserProfileListGet$Params, context?: HttpContext): Observable<Array<UserProfileDeviceReport>> {
    return this.reportUserProfileListGet$Response(params, context).pipe(
      map((r: VitalScoreStrictHttpResponse<Array<UserProfileDeviceReport>>): Array<UserProfileDeviceReport> => r.body)
    );
  }

}
